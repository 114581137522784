import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    clickedStall: {
        imageURL: "",
        name: "",
        sft: "",
        phase: ""
    }
};

const stallSlice = createSlice({
    name: 'stall',
    initialState,
    reducers: {
        setClickedStall: (state, action) => {
            state.clickedStall = action.payload;
        }
    },
});

export const { setClickedStall } = stallSlice.actions;

export default stallSlice.reducer;
