import React from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

import { clearSearchItems, setSearchItems } from '../redux/features/searchSlice';
import { filterDataList, setIsFilterOnSearch } from '../redux/features/mapDataSlice';
import { callAnalyticsApi, showApiResponse } from '../http-helper-service';

const styles = {
    multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
        return state.data.isFixed ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 } : base;
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
    // custom styles
    container: (provided) => ({
        ...provided,
        width: '100%',
    }),
    menu: (provided) => ({
        ...provided,
        width: '100%',
    }),
};

const orderOptions = (values) => values;

const SearchableDropdown = () => {
    const dispatch = useDispatch();
    const { searchItems } = useSelector((state) => state.search);
    const { filteredDataList } = useSelector((state) => state.mapData);

    const onChange = async (newValue, actionMeta) => {
        switch (actionMeta.action) {
            case 'remove-value':
            case 'pop-value':
                if (actionMeta.removedValue.isFixed) {
                    return;
                }
                break;
            case 'clear':
                newValue = filteredDataList.filter((v) => v.isFixed);
                break;
            default:
                break;
        }

        const selectedStall = orderOptions(newValue);
        let selectedStallName = [];
        let isFilterOnSearch = false;
        console.log(selectedStall);
        if (selectedStall && selectedStall.name) {
            dispatch(setSearchItems({ ...selectedStall }));
            isFilterOnSearch = true;
            selectedStallName = [selectedStall.name];
        } else {
            dispatch(clearSearchItems());
        }
        dispatch(setIsFilterOnSearch(isFilterOnSearch));
        setTimeout(() => {
            dispatch(filterDataList(selectedStallName));
        }, 1);
        // make api call
        if (selectedStall && selectedStall.name) {
            const resp = await callAnalyticsApi({ "storeSearched": true, storeName: selectedStall.name });
            console.log(resp);
            showApiResponse(resp);
        }
    };

    return (
        <Select
            value={searchItems}
            styles={styles}
            isClearable={searchItems}
            name="stallSearchItems"
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={onChange}
            id="stallSearchItems"
            options={filteredDataList}
            placeholder="Search Shops"
        />
    );
};

export default SearchableDropdown;
