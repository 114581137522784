import { createSlice } from '@reduxjs/toolkit';

const initialStateSearchItems = {
    searchItems: null,
};

const initialStateCategoryItems = {
    searchCategoryItems: null
};

const initialState = {
    ...initialStateSearchItems,
    ...initialStateCategoryItems
};

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        resetSearchData: () => initialState,
        setSearchItems: (state, action) => {
            state.searchItems = { ...action.payload };
        },
        setSearchCategoryItems: (state, action) => {
            if (!!state.searchItems) {
                clearSearchItems();
            }
            state.searchCategoryItems = { ...action.payload };
        },
        clearCategorySearchItems: () => initialStateCategoryItems,
        clearSearchItems: () => initialStateSearchItems
    },
});

export const {
    resetSearchData,
    setSearchItems,
    setSearchCategoryItems,
    clearCategorySearchItems,
    clearSearchItems } =
    searchSlice.actions;

export default searchSlice.reducer;
