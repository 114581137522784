export const expiringStalls = [
    {
        name: 'Junior',
        sft: 1308,
        date: 'Aug 21st'
    },
    {
        name: 'Juiceland',
        sft: 1407,
        date: 'Sep 17th'
    },
    {
        name: 'Nuodle',
        sft: 609,
        date: 'Oct 18th'
    }
];

export const appName = "REAGAN CROSSING PHASE ";

export const renderOptions = {
    walls: {
        alpha: 0.25,
        // maxHeightCm: 120
    }
};

// common css styles 
export const smplrContainerStyles = {
    position: 'relative',
    paddingBottom: '63.5%',
    width: '100%',
    left: '-0.99%',
    top: '2%'
};

export const selectDropdownStyles = {
    multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
        return state.data.isFixed
            ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
            : base;
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
    // custom styles
    control: (provided) => ({
        ...provided,
        width: '100%', // Control width
    }),
    menu: (provided) => ({
        ...provided,
        width: '100%', // Menu width
        maxWidth: '100%', // Prevent menu from expanding beyond control width
        overflow: 'hidden', // Prevent overflow issues
    }),
    menuList: (provided) => ({
        ...provided,
        maxHeight: '300px', // Limit menu height
        overflowY: 'auto', // Allow scrolling if needed
    }),
};