export const stalls = [

  {
    name: 'Akshaya',
    category: 'Food and Beverages',
    sft: 500,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 25.96991539865391, z: -10.331500312052913, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 25.966607403831375, z: -13.649571434990055, elevation: 4.572000053405763 },
      { levelIndex: 1, x: 22.896981248948567, z: -13.634008180879846, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 22.87001221545772, z: -10.359236429842511, elevation: 4.572000053405762 }
    ],
  },
  {
    name: 'Shen-Xi',
    category: 'Electronics',
    sft: 800,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 33.63332078544959, z: -18.783838536436527, elevation: 4.572000053405765 },
      { levelIndex: 1, x: 29.84567997464928, z: -18.750847452337297, elevation: 4.572000053405765 },
      { levelIndex: 1, x: 29.77402064586388, z: -9.72242934467316, elevation: 4.5720000534057545 },
      { levelIndex: 1, x: 33.555918706004434, z: -9.679625265285447, elevation: 4.572000053405769 }
    ],
  },
  {
    name: 'Wok-Hey',
    category: 'Beauty and Health',
    sft: 700,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 21.014982166023742, z: -22.35077285905746, elevation: 0.010000026479365154 },
      { levelIndex: 1, x: 20.864919063547358, z: -20.586127922474542, elevation: 0.010000026479364266 },
      { levelIndex: 1, x: 20.818336706590195, z: -20.11179544817031, elevation: 0.2025875654996181 },
      { levelIndex: 1, x: 20.879564479440635, z: -19.801313754985486, elevation: 0.010000026479363378 },
      { levelIndex: 1, x: 21.060448727195187, z: -19.659595498146853, elevation: 0.023384471825082187 },
      { levelIndex: 1, x: 22.64146691223031, z: -19.654059656749713, elevation: 0.1559901022639032 },
      { levelIndex: 1, x: 23.723172100987195, z: -19.594324230699637, elevation: 0.2808592672474566 },
      { levelIndex: 1, x: 23.638401748015454, z: -22.947776938951243, elevation: 0.010000026479363378 }]
  },
  {
    name: 'Watsons',
    category: 'Fashion',
    sft: 1200,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 23.72221540039814, z: -23.040236612231123, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 23.501161097309332, z: -23.923841091873864, elevation: 4.572000053405765 },
      { levelIndex: 1, x: 23.153891750627956, z: -24.64630351860815, elevation: 4.572000053405765 },
      { levelIndex: 1, x: 22.160372482556113, z: -25.794112096188982, elevation: 4.572000053405758 },
      { levelIndex: 1, x: 21.557523907446903, z: -26.29418735132308, elevation: 4.572000053405765 },
      { levelIndex: 1, x: 20.789366859073894, z: -26.787391230143328, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 20.522771271428255, z: -26.902680169986617, elevation: 4.572000053405763 },
      { levelIndex: 1, x: 19.496926008156606, z: -24.686026969839812, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 20.573739622142472, z: -23.48290203310271, elevation: 4.572000053405763 },
      { levelIndex: 1, x: 20.922421256401, z: -22.4402380118835, elevation: 4.572000053405763 }]
  },
  {
    name: 'Unity',
    category: 'Food and Beverages',
    sft: 1149,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 27.39522267201309, z: -4.579936732746716, elevation: 0.010000026479363378 },
      { levelIndex: 1, x: 27.388740936217182, z: -5.028742429876807, elevation: 0.010000026479365154 },
      { levelIndex: 1, x: 25.006890171682468, z: -5.043081767297103, elevation: 0.010580122131613301 },
      { levelIndex: 1, x: 25.10222667495316, z: -8.021430740706982, elevation: 0.15337386631397099 },
      { levelIndex: 1, x: 25.94127810374407, z: -7.928158348046363, elevation: 0.02587298377532843 },
      { levelIndex: 1, x: 28.678639389216706, z: -7.925525350772476, elevation: 0.010000026479363378 },
      { levelIndex: 1, x: 28.66383139476591, z: -4.57888869733611, elevation: 0.010000026479363378 }]
  },
  {
    name: 'umisushi',
    category: 'Electronics',
    sft: 675,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 17.45490126358907, z: -7.9135201435895475, elevation: 0.01000002647936249 },
      { levelIndex: 1, x: 14.037613709757062, z: -7.93113359570263, elevation: 0.013405272187954509 },
      { levelIndex: 1, x: 14.038800981707213, z: -5.114750098421373, elevation: 0.010000026479363378 },
      { levelIndex: 1, x: 17.485080702546686, z: -5.055749776958787, elevation: 0.010000026479363378 }]
  },
  {
    name: 'Tingkat-PeraMakan',
    category: 'Fashion',
    sft: 549,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 29.87464073206465, z: -37.15663970087589, elevation: 0.010000026479363378 },
      { levelIndex: 1, x: 33.656568459330835, z: -37.1600788342621, elevation: 0.08553398771843579 },
      { levelIndex: 1, x: 33.60621363807384, z: -30.615110400976636, elevation: 0.24754864319224357 },
      { levelIndex: 1, x: 29.886412386283535, z: -30.644533231323486, elevation: 0.648736242687888 },
      { levelIndex: 1, x: 29.950999121742495, z: -37.1600788342621, elevation: 3.167263860877047 },
      { levelIndex: 1, x: 33.676214535730274, z: -37.155553337934194, elevation: 3.2727960378688445 }]
  },
  {
    name: 'The-Soup-Spoon',
    category: 'Homelife',
    sft: 758,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 29.72658615826109, z: -22.069011985010924, elevation: 4.572000053405769 },
      { levelIndex: 1, x: 33.66350638615198, z: -21.986326713045415, elevation: 4.572000053405769 },
      { levelIndex: 1, x: 33.62434729767201, z: -18.790232624600275, elevation: 4.572000053405758 },
      { levelIndex: 1, x: 29.80804184099684, z: -18.67300816801741, elevation: 4.572000053405762 }]
  },
  {
    name: 'The-Dental-Hub',
    category: 'Education',
    sft: 1490,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 29.871497105606146, z: -24.82042086048797, elevation: 0.05503643843136885 },
      { levelIndex: 1, x: 33.577928378808004, z: -24.84724087485834, elevation: 0.2614208631031145 },
      { levelIndex: 1, x: 33.60621363807384, z: -22.076043788890953, elevation: 0.02597826546774673 },
      { levelIndex: 1, x: 29.830052510173722, z: -22.114726222620263, elevation: 0.5483423581469813 }]
  },
  {
    name: 'coffee-bean',
    category: 'Food and Beverages',
    sft: 1500,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 29.78251679026171, z: -28.008559943708203, elevation: 4.57200005340576 },
      { levelIndex: 1, x: 33.6612547582225, z: -27.993867418357137, elevation: 4.572000053405763 },
      { levelIndex: 1, x: 33.62888493932276, z: -30.50800014690155, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 29.864823857555823, z: -30.459627953035074, elevation: 4.572000053405763 }]
  }, {
    name: 'Stuffd',
    category: 'Electronics',
    sft: 1289,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 33.5656431434996, z: -27.91146233168509, elevation: 0.09262805356830306 },
      { levelIndex: 1, x: 33.54696416045686, z: -25.02557734142312, elevation: 0.010000026479364266 },
      { levelIndex: 1, x: 29.908704856066006, z: -24.99724087485835, elevation: 0.25703893658134547 },
      { levelIndex: 1, x: 29.871497105606146, z: -27.896356759444977, elevation: 0.08653480417129966 }]
  }, {
    name: 'Sin-Kang-TCM-Clinic',
    category: 'Beauty and Health',
    sft: 986,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 21.11863166670365, z: -7.946291492694632, elevation: 0.01000002647936249 },
      { levelIndex: 1, x: 21.134002190093636, z: -5.032638618841126, elevation: 0.010000026479364266 },
      { levelIndex: 1, x: 17.650104611551384, z: -5.079850988926101, elevation: 0.024429944144833016 },
      { levelIndex: 1, x: 17.734342594889103, z: -7.980137474102634, elevation: 0.6386476758791977 }]
  }, {
    name: 'SBCD-Korean-Tofu-House',
    category: 'Fashion',
    sft: 678,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 21.287382779579982, z: -7.917709388797886, elevation: 0.1011685634758912 },
      { levelIndex: 1, x: 21.308101346100493, z: -5.048829716467356, elevation: 0.045009153741272456 },
      { levelIndex: 1, x: 24.833460390492178, z: -5.039541512785863, elevation: 0.010000026479363378 },
      { levelIndex: 1, x: 24.783332602768837, z: -8.074099505033422, elevation: 0.03770952533756322 },
      { levelIndex: 1, x: 24.812992261820188, z: -7.948158262138855, elevation: 3.146851405105144 },
      { levelIndex: 1, x: 21.287382779579982, z: -5.152703973055294, elevation: 3.2075472289784086 }]
  },
  {
    name: 'Saizeriya',
    category: 'Homelife',
    sft: 1000,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 13.887613709757064, z: -5.087070924857564, elevation: 0.02513677149647542 },
      { levelIndex: 1, x: 10.526956620977387, z: -5.0538431667866766, elevation: 0.010000026479363378 },
      { levelIndex: 1, x: 10.518038262142227, z: -7.939686462364254, elevation: 0.09269291440564853 },
      { levelIndex: 1, x: 13.778784984354344, z: -7.970929855784021, elevation: 0.33752638863666906 }]
  }, {
    name: 'REFASH',
    category: 'Food and Beverages',
    sft: 1047,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.jpg',
    coordinates: [
      { levelIndex: 1, x: 6.509060677804641, z: -4.573925377089073, elevation: 0.05022196421653646 },
      { levelIndex: 1, x: 7.786686444997159, z: -4.588674933104013, elevation: 0.03459997684160143 },
      { levelIndex: 1, x: 7.78188697453282, z: -5.002928699861382, elevation: 0.010000026479363378 },
      { levelIndex: 1, x: 10.367521229247998, z: -5.066193760012549, elevation: 0.010000026479364266 },
      { levelIndex: 1, x: 10.334117849711348, z: -8.05894581862396, elevation: 0.02969961052203285 },
      { levelIndex: 1, x: 7.156649355477886, z: -7.948158562368271, elevation: 0.04766385230459491 },
      { levelIndex: 1, x: 6.490288613031453, z: -7.21806244305667, elevation: 0.14932452040139932 },
      { levelIndex: 1, x: 6.516984349520127, z: -7.255100507760808, elevation: 3.222797622619784 }]
  }, {
    name: 'Rainbow-Cove',
    category: 'Electronics',
    sft:678,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 2.0680229033033464, z: -16.27596381781012, elevation: 4.572000053405763 },
      { levelIndex: 1, x: 6.385101124414996, z: -16.307874984285746, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 6.408951181351559, z: -21.571866411912573, elevation: 4.572000053405763 },
      { levelIndex: 1, x: 2.294308219704719, z: -21.58643191704887, elevation: 4.57200005340576 }]
  },
  {
    name: 'QB-house',
    category: 'Beauty and Health',
    sft: 857,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 2.139233244717812, z: -21.687107988392935, elevation: 0.02995092236076946 },
      { levelIndex: 1, x: 2.1479460784801843, z: -24.37380065478742, elevation: 0.0442614868148965 },
      { levelIndex: 1, x: 6.298156411857832, z: -24.36551960017359, elevation: 0.010000026479363378 },
      { levelIndex: 1, x: 6.326653297365544, z: -21.69163581944115, elevation: 0.010000026479365154 }]
  },
  {
    name: 'Parkway-Shenton',
    category: 'Fashion',
    sft: 1465,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.jpg',
    coordinates: [
      { levelIndex: 1, x: 6.356605507137155, z: -27.36729927753965, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 2.0693552719543455, z: -27.304213193129982, elevation: 4.572000053405765 },
      { levelIndex: 1, x: 2.165821669928575, z: -24.427551976275705, elevation: 4.572000053405765 },
      { levelIndex: 1, x: 6.3930351178480915, z: -24.50456742488289, elevation: 4.572000053405765 }]
  },
  {
    name: 'Old-Chang-Kee',
    category: 'Homelife',
    sft: 1378,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 6.340288613031452, z: -30.089639295136948, elevation: 0.04202807976869671 },
      { levelIndex: 1, x: 2.136135409306719, z: -30.112309939399168, elevation: 0.0831385356656984 },
      { levelIndex: 1, x: 2.1579955071594235, z: -27.431045874645125, elevation: 0.010000026479365154 },
      { levelIndex: 1, x: 6.502047069394929, z: -27.479182795969383, elevation: 0.010000026479363378 }]
  },
  {
    name: 'Nuodle',
    category: 'Food and Beverages',
    sft: 609,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 2.15613702916244, z: -30.28433668974489, elevation: 0.010000026479361601 },
      { levelIndex: 1, x: 2.136135409306719, z: -33.48371009863341, elevation: 0.02292926877804735 },
      { levelIndex: 1, x: 6.305294676927284, z: -33.48860725546196, elevation: 0.07239884603497693 },
      { levelIndex: 1, x: 6.321211010066191, z: -30.269059692241594, elevation: 0.40227141798526755 }]
  },
  {
    name: 'MK-Levure-Naturelle',
    category: 'Electronics',
    sft: 798,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 22.872809518973035, z: -10.318772218350828, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 22.888763418541497, z: -13.621762894529, elevation: 4.572000053405763 },
      { levelIndex: 1, x: 19.765634086705074, z: -13.636232976251645, elevation: 4.572000053405763 },
      { levelIndex: 1, x: 19.746957860978917, z: -10.3111413516271, elevation: 4.572000053405762 }]
  },
  {
    name: 'Mister-Minit',
    category: 'Beauty and Health',
    sft: 832,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 16.635281512254227, z: -13.639245131656567, elevation: 4.572000053405763 },
      { levelIndex: 1, x: 19.80761489917325, z: -13.633744412978649, elevation: 4.572000053405763 },
      { levelIndex: 1, x: 19.706599638273147, z: -10.331373035866246, elevation: 4.57200005340576 },
      { levelIndex: 1, x: 16.61017944518315, z: -10.303390790603038, elevation: 4.572000053405765 }]
  },
  {
    name: 'McDonalds',
    category: 'Fashion',
    sft: 657,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 13.543706296788693, z: -13.623282707129185, elevation: 4.572000053405763 },
      { levelIndex: 1, x: 16.638005438462535, z: -13.60078633003821, elevation: 4.572000053405765 },
      { levelIndex: 1, x: 16.577269794368668, z: -10.329541029537609, elevation: 4.572000053405763 },
      { levelIndex: 1, x: 13.550023850280025, z: -10.358710944640876, elevation: 4.572000053405765 }]
  },
  {
    name: 'LiHO-Tea',
    category: 'Homelife',
    sft: 389,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 10.521160244079292, z: -10.431994751879433, elevation: 0.07764242085935003 },
      { levelIndex: 1, x: 13.468357241926233, z: -10.451994751879434, elevation: 0.20154750062123927 },
      { levelIndex: 1, x: 13.489125411852303, z: -13.496874585852305, elevation: 0.026144616182428138 },
      { levelIndex: 1, x: 10.51803816589749, z: -13.562770358133761, elevation: 0.20167048261404474 }]
  },
  {
    name: 'Kskin-logo',
    category: 'Food and Beverages',
    sft: 567,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 9.722925746810795, z: -17.076468890512505, elevation: 0.06513030756331784 },
      { levelIndex: 1, x: 9.67712596436303, z: -15.966237088345263, elevation: 0.1487635788237247 },
      { levelIndex: 1, x: 10.51216794463057, z: -14.812534033050598, elevation: 0.044448497386048835 },
      { levelIndex: 1, x: 10.52367151176487, z: -13.71401628786401, elevation: 0.10991730678955314 },
      { levelIndex: 1, x: 13.468466632895261, z: -13.721582916008051, elevation: 0.010000026479364266 },
      { levelIndex: 1, x: 13.472521227200094, z: -17.056468890512505, elevation: 0.19927473062138912 }]
  }, {
    name: 'Kawata-House-of-Socks',
    category: 'Electronics',
    sft: 398,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 19.67598281207927, z: -17.066112768870397, elevation: 0.010000026479364266 },
      { levelIndex: 1, x: 19.683418597137255, z: -13.714659733512805, elevation: 0.01000002647936249 },
      { levelIndex: 1, x: 16.736216277179963, z: -13.715393840407893, elevation: 0.010000026479363378 },
      { levelIndex: 1, x: 16.742468233097114, z: -17.056468890512505, elevation: 0.4133487522057502 }]
  }, {
    name: 'Kai-Oh-Don',
    category: 'Beauty and Health',
    sft: 1109,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 16.54768266680317, z: -13.742974634385979, elevation: 0.010000026479363378 },
      { levelIndex: 1, x: 13.639125411852302, z: -13.76405652322211, elevation: 0.12711012010253508 },
      { levelIndex: 1, x: 13.657855146991867, z: -17.076468890512505, elevation: 0.11377904370368785 },
      { levelIndex: 1, x: 16.5602325719392, z: -17.035329959920272, elevation: 0.22822986967334202 }]
  },
  {
    name: 'Junior',
    category: 'Fashion',
    sft: 1308,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 22.84093268163146, z: -17.14920504141594, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 19.808442051487674, z: -17.20980547006683, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 19.835271316949907, z: -13.611828408205781, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 22.81465264679212, z: -13.62212085317405, elevation: 4.572000053405762 }]
  }, {
    name: 'Juiceland',
    category: 'Homelife',
    sft: 1407,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 22.82491645751672, z: -17.070682735413115, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 26.83826444482025, z: -17.103347437551193, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 26.866015377031335, z: -15.918001052995196, elevation: 4.572000053405772 },
      { levelIndex: 1, x: 25.939826270020685, z: -14.79901788403929, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 25.932625401338065, z: -13.612930560280994, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 22.81034623217785, z: -13.603472079595647, elevation: 4.572000053405763 }]
  },
  {
    name: 'Jia-Xiang-Sarawak-Kuching-Kolomee',
    category: 'Food and Beverages',
    sft: 609,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 27.223951129099635, z: -19.654059656749713, elevation: 0.056909485109718716 },
      { levelIndex: 1, x: 27.239754930447802, z: -22.986430455198917, elevation: 0.1493595855471046 },
      { levelIndex: 1, x: 27.14368633785092, z: -23.367881309901005, elevation: 0.13744733841486667 },
      { levelIndex: 1, x: 27.016621471451813, z: -23.708967967322117, elevation: 0.010000026479364266 },
      { levelIndex: 1, x: 23.858740789917697, z: -23.010889511649392, elevation: 0.08516714449890106 },
      { levelIndex: 1, x: 24.03493715369715, z: -19.766803659549527, elevation: 0.95742622390223 }]
  }, {
    name: 'iuga',
    category: 'Electronics',
    sft: 587,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 27.102150901234644, z: -23.78637069737531, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 26.893478281492758, z: -24.92132067812943, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 26.585671087832665, z: -25.645769328832753, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 25.259103689303483, z: -27.528860764855434, elevation: 4.572000053405778 },
      { levelIndex: 1, x: 22.65104362214865, z: -25.30372612953707, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 23.111356117062567, z: -24.635798484807598, elevation: 4.572000053405765 },
      { levelIndex: 1, x: 23.505870896709208, z: -23.865450497643653, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 23.728393398422906, z: -23.071413264015554, elevation: 4.572000053405763 }]
  },
  {
    name: 'ichiban',
    category: 'Beauty and Health',
    sft: 875,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 25.246738617770898, z: -27.57929524872998, elevation: 4.572000053405772 },
      { levelIndex: 1, x: 24.550479610044857, z: -28.340158598672495, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 23.653894786543635, z: -29.09479124000164, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 22.67449343964742, z: -29.72109647956195, elevation: 4.572000053405772 },
      { levelIndex: 1, x: 22.001855985391455, z: -30.065383681040355, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 20.506441065222063, z: -26.91941331673352, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 21.431586733634457, z: -26.354062337102842, elevation: 4.572000053405769 },
      { levelIndex: 1, x: 22.03308979290622, z: -25.880765365273486, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 22.470833298373698, z: -25.460825184563596, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 22.556722702649367, z: -25.374351437429365, elevation: 4.572000053405762 }]
  },
  {
    name: 'Hundred-Grains',
    category: 'Fashion',
    sft: 465,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 21.964699719610277, z: -30.097238207718284, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 20.002813230596715, z: -30.764880360232805, elevation: 4.572000053405763 },
      { levelIndex: 1, x: 18.777754756679403, z: -30.925445620541982, elevation: 4.572000053405763 },
      { levelIndex: 1, x: 18.146005800425808, z: -30.918647857035616, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 18.239689985247356, z: -27.52381141100086, elevation: 4.061497808484227 },
      { levelIndex: 1, x: 19.13266275956632, z: -27.452581590162424, elevation: 4.230856103629551 },
      { levelIndex: 1, x: 19.43572499622463, z: -27.28013791710854, elevation: 4.572000053405763 },
      { levelIndex: 1, x: 19.853427196078552, z: -27.21634088625098, elevation: 4.572000053405765 },
      { levelIndex: 1, x: 20.46945176439539, z: -26.927985358368495, elevation: 4.572000053405763 }]
  }, {
    name: 'Heavenly-Wang',
    category: 'Homelife',
    sft: 854,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 18.12236003017498, z: -30.889385068468656, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 16.435054065939614, z: -30.816681521578804, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 15.563382597310834, z: -30.553011675437606, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 14.337477892026273, z: -30.052254517174113, elevation: 4.572000053405763 },
      { levelIndex: 1, x: 15.884742223816996, z: -26.91155317151418, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 16.567004473607522, z: -27.077755673926028, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 17.347405584880992, z: -27.283478928715496, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 18.115221597724222, z: -27.44891088876557, elevation: 4.572000053405762 }]
  },
  {
    name: 'Goldlion-Outlet',
    category: 'Food and Beverages',
    sft: 987,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 11.206805705587687, z: -27.556512841902443, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 13.756488700275119, z: -25.360833294195388, elevation: 4.572000053405765 },
      { levelIndex: 1, x: 14.131734864923663, z: -25.765419035027445, elevation: 4.572000053405765 },
      { levelIndex: 1, x: 14.309871642141394, z: -25.8680208404112, elevation: 4.572000053405769 },
      { levelIndex: 1, x: 14.958503889177287, z: -26.40867812923416, elevation: 4.572000053405769 },
      { levelIndex: 1, x: 15.205639855964868, z: -26.523115456899312, elevation: 4.572000053405765 },
      { levelIndex: 1, x: 15.83604129148071, z: -26.95725472067918, elevation: 4.572000053405763 },
      { levelIndex: 1, x: 14.293429490397951, z: -30.043602827922463, elevation: 4.572000053405763 },
      { levelIndex: 1, x: 11.90265491554281, z: -28.461125806283945, elevation: 4.572000053405762 }]
  },
  {
    name: 'Flower-Matters',
    category: 'Electronics',
    sft: 1087,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 9.299065267499856, z: -23.856162493223238, elevation: 4.572000053405763 },
      { levelIndex: 1, x: 9.367502093970774, z: -23.843873771581652, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 9.617729151182441, z: -24.943599162749177, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 9.871562491012241, z: -25.734189270879057, elevation: 4.57200005340576 },
      { levelIndex: 1, x: 10.148156042094623, z: -26.219139855356218, elevation: 4.572000053405763 },
      { levelIndex: 1, x: 11.167744786963004, z: -27.526678577879462, elevation: 4.572000053405769 },
      { levelIndex: 1, x: 13.729129670471021, z: -25.380040390443682, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 13.196235679147462, z: -24.425250610271913, elevation: 4.572000053405765 },
      { levelIndex: 1, x: 12.85407440198169, z: -23.70315194336854, elevation: 4.572000053405765 },
      { levelIndex: 1, x: 12.645825377205714, z: -23.030616353440948, elevation: 4.572000053405763 }]
  },
  {
    name: 'FairPrice',
    category: 'Beauty and Health',
    sft: 758,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 12.524504238466466, z: -19.56858897892083, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 12.562614922931164, z: -22.687204020139816, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 12.66903517822287, z: -23.035399994401413, elevation: 4.572000053405763 },
      { levelIndex: 1, x: 9.356502062059064, z: -23.893037451810564, elevation: 4.572000053405763 },
      { levelIndex: 1, x: 9.173719898005139, z: -23.397291151682786, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 9.06491806858416, z: -22.86312593391338, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 9.093676856891195, z: -19.592637889546605, elevation: 4.572000053405762 }]
  },
  {
    name: 'Esmond-Service-Sq',
    category: 'Fashion',
    sft: 1409,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 16.828589318631245, z: -24.74010430082608, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 18.136925399439352, z: -24.899223958542464, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 19.459771542147422, z: -24.710333110206925, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 20.495212516977244, z: -26.909428466572546, elevation: 4.572000053405763 },
      { levelIndex: 1, x: 18.83815303664702, z: -27.454011056693194, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 17.352698428036362, z: -27.349939444575128, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 15.886758050426723, z: -26.9179397708757, elevation: 4.572000053405767 }]
  },
  {
    name: 'engs-wanton-noodle-',
    category: 'Homelife',
    sft: 945,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 15.962869402226913, z: -26.95512456955639, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 15.055400763984201, z: -26.44553260323476, elevation: 4.572000053405765 },
      { levelIndex: 1, x: 14.456117061448344, z: -25.97182495950656, elevation: 4.5720000534057705 },
      { levelIndex: 1, x: 13.790046879861698, z: -25.37890252631866, elevation: 4.572000053405779 },
      { levelIndex: 1, x: 13.158176671421534, z: -24.469244867410563, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 12.783598074845006, z: -23.740842021626086, elevation: 4.572000053405765 },
      { levelIndex: 1, x: 12.664556183072213, z: -23.031854166355867, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 15.450120095039, z: -22.450644811543242, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 15.71416834557842, z: -23.142351812755912, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 15.917561339535338, z: -23.47957139137766, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 16.195950663277866, z: -23.873600161650398, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 16.318710804090717, z: -24.059705915140697, elevation: 4.572000053405765 },
      { levelIndex: 1, x: 16.82347196277169, z: -24.700129007297296, elevation: 4.572000053405762 }]
  },
  {
    name: 'Self-Service-Banking',
    category: 'Food and Beverages',
    sft: 789,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 1, x: 12.54993679532895, z: -19.578162464952875, elevation: 4.572000053405763 },
      { levelIndex: 1, x: 15.177005892411193, z: -19.601145700292665, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 15.445691114405944, z: -19.62350911415708, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 15.625955202336597, z: -19.800314710296725, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 15.363989804552116, z: -22.381532336444984, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 12.640538518243725, z: -23.044122455196778, elevation: 4.572000053405762 },
      { levelIndex: 1, x: 12.489168004262716, z: -22.680619797156254, elevation: 4.572000053405763 }]
  },
  {
    name: 'Dancepointe-Academy',
    category: 'Electronics',
    sft: 678,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 14.626494283581458, z: -30.32058014168065, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 14.627408701630989, z: -33.22518438137567, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 18.27962171682704, z: -33.20866347143505, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 18.28794772343167, z: -30.343439849143035, elevation: 4.572000026702881 }]
  },
  {
    name: 'Daiso',
    category: 'Beauty and Health',
    sft: 977,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 19.061977619808086, z: -24.271744451607002, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 19.05400832432961, z: -27.498836529379663, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 16.02418954811068, z: -27.492594284917878, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 16.104937737328562, z: -24.257606603359473, elevation: 4.572000026702883 }]
  },
  {
    name: 'Dpenyetz',
    category: 'Fashion',
    sft: 1305,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 19.018828028041778, z: -20.813066942025635, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 19.038945736358496, z: -24.25660314674517, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 16.011373322039116, z: -24.231153646804376, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 15.987050721997937, z: -20.765394938213888, elevation: 4.572000026702883 }]
  },
  {
    name: 'Crocs',
    category: 'Homelife',
    sft: 1366,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 22.284465210160555, z: -24.279350764884015, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 22.280237829928517, z: -20.803877766909093, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 19.022634725675246, z: -20.843505602839898, elevation: 4.572000026702879 },
      { levelIndex: 0, x: 19.069200572069068, z: -24.32636488032015, elevation: 4.572000026702883 }]
  },
  {
    name: 'Cristofori-Music-School',
    category: 'Food and Beverages',
    sft: 1489,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 22.30570287297283, z: -27.503298467472437, elevation: 4.572000026702884 },
      { levelIndex: 0, x: 19.03676539044895, z: -27.50639576835122, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 19.17165419486521, z: -24.231191716019488, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 22.34212193358336, z: -24.262399374359216, elevation: 4.572000026702882 }]
  },
  {
    name: 'Creative-Hairworkz',
    category: 'Electronics',
    sft: 1500,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 26.20911909137163, z: -22.15308839217797, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 25.322374166014566, z: -23.20002173897763, elevation: 4.57200002670288 },
      { levelIndex: 0, x: 25.304511708426826, z: -24.260975258611804, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 22.28038398777316, z: -24.312581981044254, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 22.251177983565164, z: -20.85090152223998, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 26.175940932736797, z: -20.777623304893886, elevation: 4.572000026702882 }]
  },
  {
    name: 'Coluti-Vision',
    category: 'Beauty and Health',
    sft: 787,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 9.810035138085054, z: -24.25291620720098, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 9.78567392158447, z: -23.164607231088663, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 9.1072683867025, z: -22.136470832714934, elevation: 4.572000026702888 },
      { levelIndex: 0, x: 9.08892609139409, z: -20.76091994572449, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 12.88793011675766, z: -20.738090798643835, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 12.88257276631047, z: -24.27237663085254, elevation: 4.572000026702879 }]
  },
  {
    name: 'ColourFul-Day-Café',
    category: 'Fashion',
    sft: 976,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 12.840830898601936, z: -20.776795107263197, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 15.99078375080315, z: -20.828745212162605, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 15.997562881693169, z: -24.26793753399659, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 12.873843938017979, z: -24.204007496203705, elevation: 4.572000026702881 }]
  },
  {
    name: 'Chuan-Taizi-Malatang',
    category: 'Homelife',
    sft: 234,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 12.912233605187105, z: -24.245405772426043, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 12.892459045967716, z: -27.5122210376698, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 9.81664958428512, z: -27.541837181579908, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 9.796543664616053, z: -24.26919047194526, elevation: 4.572000026702881 }]
  },
  {
    name: 'Chong-Pang-Old-Time-Flavour',
    category: 'Food and Beverages',
    sft: 686,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 12.885766769359524, z: -27.508598759269802, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 12.91363594085223, z: -24.26922457837614, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 16.023374046154032, z: -24.26302979706398, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 15.97603847842734, z: -27.516087307358088, elevation: 4.572000026702881 }]
  },
  {
    name: 'Chiropracic-First',
    category: 'Electronics',
    sft: 976,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 12.885766769359524, z: -27.508598759269802, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 12.91363594085223, z: -24.26922457837614, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 16.023374046154032, z: -24.26302979706398, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 15.97603847842734, z: -27.516087307358088, elevation: 4.572000026702881 }]
  }, {
    name: 'Canton-Paradise',
    category: 'Beauty and Health',
    sft: 1002,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 10.82214922661525, z: -33.269405024921625, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 14.590766518112854, z: -33.24394132774569, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 14.609234634141853, z: -30.312530082554847, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 10.818410226241255, z: -30.295333343543867, elevation: 4.57200002670288 }]
  },
  {
    name: 'Burger-King',
    category: 'Fashion',
    sft: 1500,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 7.074916148195391, z: -30.31012675836419, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 10.797926614403744, z: -30.332578924845173, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 10.845932813339934, z: -33.23641276902366, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 7.045685258475008, z: -33.30145747883929, elevation: 4.572000026702882 }]
  },
  {
    name: 'BFT',
    category: 'Homelife',
    sft: 1467,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 18.280903216872602, z: -30.287001225049046, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 21.77291548819116, z: -30.310293448523772, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 21.749764357325933, z: -33.24848625547333, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 18.30241048162648, z: -33.25583855605516, elevation: 4.572000026702881 }]
  },
  {
    name: 'Akshaya-by-Navrang',
    category: 'Food and Beverages',
    sft: 1398,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 21.791275663553357, z: -30.331949449588432, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 25.331365462021413, z: -30.340437436838624, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 25.321294096353796, z: -33.235773941916555, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 21.744942692548573, z: -33.24776469766185, elevation: 4.572000026702883 }]
  }, {
    name: 'aikiForest-Aikido',
    category: 'Electronics',
    sft: 1267,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 25.280005064835674, z: -30.291740110177543, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 29.308403364334506, z: -30.354077974770966, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 29.350459704134458, z: -33.29044824268619, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 25.284885132223057, z: -33.25512742090523, elevation: 4.572000026702882 }]
  },
  {
    name: 'Adeline-Beauty-Spa',
    category: 'Beauty and Health',
    sft: 1145,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 24.90290820314733, z: -27.58380173039167, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 24.906001169051112, z: -27.533723779108204, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 22.35232829753527, z: -27.504178500489644, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 22.25850844833502, z: -24.291525842570238, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 25.32208864284978, z: -24.22189649991102, elevation: 4.57200002670288 },
      { levelIndex: 0, x: 25.311969595750167, z: -27.05683178547785, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 25.266170195801074, z: -27.42878487917613, elevation: 4.572000026702882 }]
  },
  {
    name: 'Eleven',
    category: 'Fashion',
    sft: 1054,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 5.622318595729044, z: -19.02758251240923, elevation: 4.57200002670288 },
      { levelIndex: 0, x: 2.0571975961541717, z: -19.06670801996779, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 2.033170603965336, z: -31.54200413967913, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 5.53964253922919, z: -31.48459283472883, elevation: 4.57200002670288 }]
  },
  {
    name: 'Akshaya',
    category: 'Homelife',
    sft: 965,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 5.596968284345813, z: -15.97891618684762, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 5.522728183453213, z: -19.068078904305338, elevation: 3.0000000000000018 },
      { levelIndex: 0, x: 2.0684484199477224, z: -19.01838672797936, elevation: 3.1808952170628197 },
      { levelIndex: 0, x: 2.0885298827814167, z: -15.970589641262858, elevation: 4.572000026702882 }]
  },
  {
    name: 'Shen-Xi',
    category: 'Food and Beverages',
    sft: 896,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 2.0645513687085955, z: -12.91962775631491, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 5.621009044247561, z: -12.904346170935424, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 5.5874076661846726, z: -15.989785188515205, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 2.0464209904465047, z: -15.983087304611864, elevation: 4.572000026702881 }]
  },
  {
    name: 'Wok-Hey',
    category: 'Electronics',
    sft: 756,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 5.651608121933839, z: -12.898768199501784, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 5.613482051101443, z: -12.956155442606866, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 2.068898948792369, z: -12.947540029283296, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 2.1044646710283437, z: -9.958504195083382, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 5.572967668507232, z: -9.987037791338468, elevation: 4.572000026702882 }]
  },
  {
    name: 'Watsons',
    category: 'Beauty and Health',
    sft: 678,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 5.622942127035755, z: -6.741773159147566, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 2.116474404881274, z: -6.741323043074244, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 2.117110737061681, z: -9.957281858389445, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 5.600550823200479, z: -9.945137300425873, elevation: 4.572000026702881 }]
  },
  {
    name: 'Unity',
    category: 'Fashion',
    sft: 567,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 5.591522450213189, z: -6.778725783265642, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 2.082205722584611, z: -6.779042234160575, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 2.069718294430152, z: -4.489759702644603, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 5.608814291719845, z: -4.4802032190286845, elevation: 4.572000026702882 }]
  },
  {
    name: 'umisushi',
    category: 'Homelife',
    sft: 456,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 29.29794285682702, z: -22.14967747156756, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 29.539193086941808, z: -21.72824684272299, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 33.67374776578255, z: -21.78331876505955, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 33.69571902055178, z: -27.236116374687526, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 29.36178416517144, z: -27.33020336704391, elevation: 4.572000026702881 }]
  },
  {
    name: 'Tingkat-PeraMakan',
    category: 'Food and Beverages',
    sft: 345,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 33.68288659372705, z: -19.259809207686907, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 30.19613818850321, z: -19.268277756464308, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 30.253317674892998, z: -18.637547297327924, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 29.87461472834078, z: -18.77353188187278, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 29.532834915662086, z: -18.64712703077913, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 29.349610521649847, z: -18.212677124071845, elevation: 4.572000026702884 },
      { levelIndex: 0, x: 29.482766040700753, z: -16.225775323326747, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 33.64762392954097, z: -16.2284762066796, elevation: 4.572000026702881 }]
  },
  {
    name: 'The-Soup-Spoon',
    category: 'Electronics',
    sft: 235,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 29.426845970384488, z: -13.453669273317281, elevation: 3.154353261419031 },
      { levelIndex: 0, x: 33.669988922440346, z: -13.393193783611592, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 33.6747632831854, z: -16.266558194930706, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 29.35787091392508, z: -16.277427881731754, elevation: 4.572000026702883 }]
  },
  {
    name: 'The-Dental-Hub',
    category: 'Beauty and Health',
    sft: 866,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 29.337155753512967, z: -10.637795964977625, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 33.68217974713991, z: -10.551969607641018, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 33.67621372113213, z: -13.444612481143762, elevation: 4.408042429514386 },
      { levelIndex: 0, x: 29.42684593564892, z: -13.426239552338817, elevation: 3.01722933748458 }]
  },
  {
    name: 'coffee-bean',
    category: 'Fashion',
    sft: 1078,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 33.68533172209699, z: -10.58870680163142, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 29.35484068719186, z: -10.674357271425587, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 29.335289623882034, z: -7.776626502775985, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 33.650627872147254, z: -7.729819908356999, elevation: 4.572000026702882 }]
  },
  {
    name: 'Stuffd',
    category: 'Homelife',
    sft: 1377,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 33.644934939232996, z: -7.796912912528597, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 33.68558717289968, z: -6.776542152768615, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 31.410770369953763, z: -4.493515629905319, elevation: 4.572000026702953 },
      { levelIndex: 0, x: 29.301487554555358, z: -4.515972633179109, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 29.37766832437172, z: -7.769058913319752, elevation: 4.572000026702882 }]
  },
  {
    name: 'Sin-Kang-TCM-Clinic',
    category: 'Food and Beverages',
    sft: 1333,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 14.85192505638678, z: -15.576063296540607, elevation: 4.572000026702884 },
      { levelIndex: 0, x: 15.096027896596285, z: -17.97888289524516, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 15.026626866901086, z: -18.226075432359828, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 14.741869887131456, z: -18.40306867211769, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 11.881858723243164, z: -18.354236156797985, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 12.045935219840773, z: -14.840760817517076, elevation: 4.572000026702881 }]
  },
  {
    name: 'SBCD-Korean-Tofu-House',
    category: 'Electronics',
    sft: 1245,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 20.473022932345046, z: -18.350436549758356, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 20.208381943061543, z: -18.235036359026903, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 20.09464316509233, z: -18.064994799502678, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 20.2794191009969, z: -15.580848124223031, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 23.130719896910417, z: -14.80998457865649, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 23.32071134789245, z: -15.405622091529779, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 23.26305236654391, z: -18.34657736048663, elevation: 4.572000026702882 }]
  },
  {
    name: 'Saizeriya',
    category: 'Beauty and Health',
    sft: 789,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 26.477050047625333, z: -14.030045645666524, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 26.7302936273114, z: -14.588399015355698, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 26.770335465900082, z: -15.056727126506056, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 26.763386210193595, z: -18.345026859305996, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 23.27143716363142, z: -18.32267806761098, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 23.287593238436468, z: -15.399198213301013, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 23.074262966524543, z: -14.814735643319072, elevation: 4.572000026702882 }]
  },
  {
    name: 'REFASH',
    category: 'Fashion',
    sft: 456,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.jpg',
    coordinates: [
      { levelIndex: 0, x: 12.022115539207505, z: -14.795287788883757, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 11.940882438890263, z: -18.371008351325788, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 8.49083265975909, z: -18.366397447009053, elevation: 4.572000026702879 },
      { levelIndex: 0, x: 8.449551819124448, z: -15.148481048225657, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 8.651711660431882, z: -14.189660567218013, elevation: 4.572000026702881 }]
  },
  {
    name: 'Rainbow-Cove',
    category: 'Homelife',
    sft: 787,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 14.866942096101514, z: -15.572643424078814, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 12.029520737299787, z: -14.809706523223083, elevation: 4.572000026702884 },
      { levelIndex: 0, x: 12.436592929463707, z: -13.669204671658882, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 13.186700355393409, z: -12.612978980941032, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 14.237183201753671, z: -11.588145324334121, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 15.231594010454474, z: -11.016001162557565, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 16.2597388459117, z: -13.272841389548958, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 15.28524746982214, z: -14.287619397778116, elevation: 4.572000026702882 }]
  },
  {
    name: 'QB-house',
    category: 'Food and Beverages',
    sft: 785,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 15.236718524641269, z: -11.063773537274887, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 16.41650311011011, z: -10.662186030678631, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 17.059689719418685, z: -10.566014726626006, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 17.622772746367957, z: -10.60908453196298, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 17.995729999136948, z: -10.538935460220248, elevation: 4.572000026702893 },
      { levelIndex: 0, x: 18.86838687613698, z: -10.76142833021358, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 19.88655521851577, z: -10.99588145632851, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 18.951163794913697, z: -13.24599753458767, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 17.642170831041902, z: -12.933801384353217, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 16.286364202593738, z: -13.242943810705048, elevation: 4.572000026702882 }]
  },
  {
    name: 'Parkway-Shenton',
    category: 'Electronics',
    sft: 576,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.jpg',
    coordinates: [
      { levelIndex: 0, x: 19.849986948811605, z: -11.034593022564623, elevation: 4.572000026702884 },
      { levelIndex: 0, x: 20.967466031593858, z: -11.56036616105999, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 21.445723710293553, z: -11.985778803807525, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 22.00320841827656, z: -12.61377179623696, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 22.486002603291784, z: -13.252137087146124, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 22.875399345926947, z: -13.947110249859529, elevation: 4.5720000267028835 },
      { levelIndex: 0, x: 23.13428220792138, z: -14.791511242195547, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 20.33419318184968, z: -15.535831407060256, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 20.0239135562149, z: -14.252551199762541, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 18.889070054447533, z: -13.249883439508734, elevation: 4.572000026702883 }]
  },
  {
    name: 'Nuodle',
    category: 'Beauty and Health',
    sft: 976,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 24.62002301352851, z: -10.363915725352818, elevation: 4.572000026702886 }, { levelIndex: 0, x: 25.599935116179243, z: -11.823980654091589, elevation: 4.572000026702895 }, { levelIndex: 0, x: 26.213980070068352, z: -12.957033609704101, elevation: 4.572000026702882 }, { levelIndex: 0, x: 26.529365151099473, z: -14.122324050039616, elevation: 4.572000026702882 }, { levelIndex: 0, x: 23.084550900114703, z: -14.799246760401173, elevation: 4.572000026702882 }
      , { levelIndex: 0, x: 22.818605408138744, z: -13.966653087053212, elevation: 4.572000026702882 }, { levelIndex: 0, x: 22.452874777753863, z: -13.27157029347084, elevation: 4.572000026702885 }, { levelIndex: 0, x: 21.908034515637617, z: -12.622039071078083, elevation: 4.572000026702882 }]
  },
  {
    name: 'MK-Levure-Naturelle',
    category: 'Fashion',
    sft: 456,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 21.34966510551507, z: -7.907224240919152, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 21.91926580690565, z: -8.168970976668238, elevation: 4.572000026702885 },
      { levelIndex: 0, x: 23.229531277769407, z: -9.017344780027821, elevation: 4.572000026702884 },
      { levelIndex: 0, x: 23.864706533112606, z: -9.463561472807829, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 24.629265720284717, z: -10.35437506128861, elevation: 4.572000026702892 },
      { levelIndex: 0, x: 21.99645492833853, z: -12.625879413440305, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 21.473157660112776, z: -12.051440170758074, elevation: 4.572000026702885 },
      { levelIndex: 0, x: 20.92697499854572, z: -11.614439640296139, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 19.84663325257863, z: -11.050631886765977, elevation: 4.572000026702882 }]
  },
  {
    name: 'Jia-Xiang-Sarawak-Kuching-Kolomee',
    category: 'Homelife',
    sft: 1356,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 17.599985845096665, z: -7.13195874806706, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 18.44705718890896, z: -7.075057006744137, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 19.607798724856274, z: -7.321673401605031, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 20.859155449036855, z: -7.628452725539036, elevation: 4.572000026702879 },
      { levelIndex: 0, x: 21.450074788220657, z: -7.923802801017198, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 19.879129844821456, z: -11.003641081271676, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 18.86379033758002, z: -10.777198037010058, elevation: 4.572000026702884 },
      { levelIndex: 0, x: 17.91177852400053, z: -10.597599887366991, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 17.539952539504462, z: -10.58986117664602, elevation: 4.572000026702881 }]
  },
  {
    name: 'Flower-Matters',
    category: 'Food and Beverages',
    sft: 1457,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 13.721861496591423, z: -7.940640598488329, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 15.850040333720404, z: -7.198530018506542, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 16.940977509058566, z: -7.071726155499686, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 17.577071327454682, z: -7.090683966976277, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 17.618810432419938, z: -10.519888330983408, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 16.478873392880914, z: -10.669511477418794, elevation: 4.5720000267028835 },
      { levelIndex: 0, x: 15.288848542121988, z: -11.037517063493153, elevation: 4.572000026702883 }]
  },
  {
    name: 'Old-Chang-Kee',
    category: 'Electronics',
    sft: 1245,
    phase: 1,
    store: 'no',
    vacantStatus: 'expired',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 10.53963168477862, z: -10.48641091750402, elevation: 4.572000026702889 },
      { levelIndex: 0, x: 11.398366791598109, z: -9.463228901259574, elevation: 4.572000026702885 },
      { levelIndex: 0, x: 12.280399512869263, z: -8.724336601089854, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 13.762386374627344, z: -7.935068301425555, elevation: 4.572000026702884 },
      { levelIndex: 0, x: 15.27503342857851, z: -11.065068521898416, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 14.285684752233536, z: -11.543366391214523, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 13.254619561409053, z: -12.634431571771279, elevation: 4.572000026702883 }]
  },
  {
    name: 'Kskin-logo',
    category: 'Beauty and Health',
    sft: 1394,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://trynbuy-vto.s3.amazonaws.com/360/navigation_images/001.png',
    coordinates: [
      { levelIndex: 0, x: 8.719974816934903, z: -14.135113620049559, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 9.036380612068871, z: -12.99015577079161, elevation: 4.572000026702883 },
      { levelIndex: 0, x: 9.412850331891093, z: -12.060563436628845, elevation: 4.572000026702881 },
      { levelIndex: 0, x: 9.995647540295524, z: -11.059229059627103, elevation: 4.572000026702896 },
      { levelIndex: 0, x: 10.56291315664061, z: -10.488369891675793, elevation: 4.572000026702893 },
      { levelIndex: 0, x: 13.240734958635072, z: -12.615662538089229, elevation: 4.5720000267028835 },
      { levelIndex: 0, x: 12.46628728836446, z: -13.678571893148755, elevation: 4.572000026702882 },
      { levelIndex: 0, x: 12.05358400316275, z: -14.777821075322187, elevation: 4.5720000267028835 }]
  },
];

export const sensors = [
  {
    id: 'Ready to Occupy',
    position: {
      levelIndex: 0, x: 3.5090640898070085, z: -3.256354923487797, elevation: 0.4
    }
  }
]