// import { appName } from '../app-constants';
import React, { useEffect, useState } from 'react';
import '../css/map-filters.css';

import { useDispatch, useSelector } from 'react-redux';
import { clearDataList, clearVacantStatus, defaultStall, filterDataListByOccupancyStatus, filterDataListByRange, loadPathFinder, setClickedStall, setVacantStatus } from '../redux/features/mapDataSlice';
import SearchableDropdown from './SearchableDropdown';
import { clearSearchItems } from '../redux/features/searchSlice';
import { callAnalyticsApi, showApiResponse } from '../http-helper-service';
import { expiringStalls } from '../app-constants';

const MapFilters = () => {

    const dispatch = useDispatch();
    const { clickedStall, vacantStatus } = useSelector(state => state.mapData);
    const searchItems = useSelector(state => state.search.serachItems);

    const [areaRange, setAreaRange] = useState(1800);
    const [destinationName, setDestinationName] = useState(null);

    useEffect(() => {
        console.log(searchItems);
        dispatch(setClickedStall(defaultStall));

    }, [dispatch, searchItems]);

    const filterMap = async (filterType, event) => {
        const filterMapClasses = {
            "occupied": "btn-occ",
            "expired": "btn-exp",
            "vacant": "btn-vac"
        };

        const selectedElement = event.target;
        // document.getElementById(filterType);
        const filterTypeClass = filterMapClasses[filterType];

        const selectedFilterType = {
            type: filterType,
            selected: !selectedElement.classList.contains(filterTypeClass)
        };

        selectedElement.classList.toggle("btn-default");
        selectedElement.classList.toggle(filterTypeClass);

        console.log(vacantStatus);
        let updatedVacantStatuses = [...vacantStatus];
        if (selectedFilterType.selected) {
            updatedVacantStatuses.push(filterType);
        } else {
            updatedVacantStatuses = vacantStatus.filter(item => item !== filterType);
        }
        if (updatedVacantStatuses && updatedVacantStatuses.length > 0) {
            dispatch(setVacantStatus([...updatedVacantStatuses]));
        } else {
            dispatch(clearVacantStatus());
            dispatch(clearDataList());
        }
        dispatch(filterDataListByOccupancyStatus(updatedVacantStatuses));
        // clear the search filter items
        setTimeout(() => {
            dispatch(clearSearchItems());
        }, 10);
        // make api call
        const resp = await callAnalyticsApi({ "occupancyVerified": true, filterType });
        console.log(resp);
        showApiResponse(resp);
    };

    const triggerDestinationChange = (ev) => {
        const value = ev.target.value;
        console.log(value);
        setDestinationName(value);
        // triggerMapFilters({ methodName: "handleDestinationChange", value: ev.target.value });
    };

    const triggerDirectionPathOnButtonClick = async () => {
        console.log('triggerDirectionPathOnButtonClick');
        const pathData = {
            destinationName: destinationName
        }
        dispatch(loadPathFinder(pathData));
        // make api call
        const resp = await callAnalyticsApi({ "storeViewed": true, "navigationFound": true, storeName: clickedStall['name'] });
        console.log(resp);
        showApiResponse(resp);
    };

    const getSelectedRange = (val) => {
        // const val = event.target.value;
        console.log(val);
        setAreaRange(val);
        dispatch(filterDataListByRange(val));
    };

    return (
        <form className="pure-form">
            <div className="row no-gutters">

                <div className="col-lg-12 section-divider search-dropdown-container">
                    <SearchableDropdown />
                </div>

                <div className="col-lg-12">
                    <label>Occupancy Status</label>
                    <div id="occupancyStatus" className="btn-group" role="group" aria-label="Map filter statuses">
                        <button id="occupied" type="button" className='btn btn-occ' onClick={(event) => filterMap('occupied', event)}>Occupied</button>
                        <button id="expired" type="button" className='btn btn-exp' onClick={(event) => filterMap('expired', event)}>Expiring</button>
                        <button id="vacant" type="button" className='btn btn-vac' onClick={(event) => filterMap('vacant', event)}>Vacant</button>
                    </div>
                </div>

                <div className="col-lg-12">
                    <label>
                        Shop size: 100 to {areaRange} sqft
                    </label>
                    <input className='alignment' type="range" id="vol" name="vol" min="100" step="100" max="1800"
                        value={areaRange} onChange={(ev) => getSelectedRange(ev.target.value)} />
                </div>

                <div className="col-lg-12 ">
                    <label htmlFor="occupancyStatus">Contracts expiring soon
                    </label>
                </div>
                <div className='row no-gutters row_align'>
                    <div className="col-lg-12">
                        <table className='table responsive-table'>
                            {expiringStalls.map(stall =>
                            (<tr style={{ borderBottom: '1px solid #e8e8e8' }}>
                                <td> {stall.name}</td>
                                <td> {stall.sft} sqrt</td>
                                <td> {stall.date}</td>
                            </tr>)
                            )}
                        </table>
                    </div>
                </div>

                {(clickedStall && clickedStall.imageURL !== "") &&
                    <>
                        <div className="col-lg-12">
                            <label>{clickedStall.name}</label>
                        </div>

                        <div className='col-lg-6 col-xs-6'>
                            Area: {clickedStall.sft} SFT
                        </div>
                        <div className='col-lg-6  col-xs-6 text-right'>
                            Phase: {clickedStall.phase}
                        </div>
                        <br />

                        {
                            (clickedStall && !!clickedStall.stalls) &&
                            <>

                                <div className="col-lg-7 col-xs-7 mb-2">
                                    <select className="form-select" onChange={triggerDestinationChange}>
                                        <option key='null' value=''>Please Select Store</option>
                                        {clickedStall.stalls.map(stall => stall.name).map(name => (
                                            <option key={name} value={name}>{name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-lg-5 col-xs-5 text-right">
                                    <button type="button" className="btn btn-primary text-white" onClick={triggerDirectionPathOnButtonClick}>Directions</button>
                                </div>
                            </>
                        }

                    </>
                }

            </div>
        </form >
    );
}
export default MapFilters;

