import React from 'react';

import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { filterDataListByCategory, setIsFilterOnSearch } from '../../redux/features/mapDataSlice';
import { clearSearchItems, resetSearchData, setSearchCategoryItems } from '../../redux/features/searchSlice';

const categoryOptions = [
    { value: 'Food and Beverages', label: 'Food and Beverages' },
    { value: 'Electronics', label: 'Electronics' },
    { value: 'Beauty and Health', label: 'Beauty and Health' },
    { value: 'Fashion', label: 'Fashion' },
    { value: 'Education', label: 'Education' },
    { value: 'Homelife', label: 'Homelife' },
];

const styles = {
    multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
        return state.data.isFixed
            ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
            : base;
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
    // custom styles
    control: (provided) => ({
        ...provided,
        width: '100%', // Control width
    }),
    menu: (provided) => ({
        ...provided,
        width: '100%', // Menu width
        maxWidth: '100%', // Prevent menu from expanding beyond control width
        overflow: 'hidden', // Prevent overflow issues
    }),
    menuList: (provided) => ({
        ...provided,
        maxHeight: '300px', // Limit menu height
        overflowY: 'auto', // Allow scrolling if needed
    }),
};

const orderOptions = (values) => {
    return values;
};

const FilterDropdown = () => {

    const dispatch = useDispatch();
    const { searchItems, searchCategoryItems } = useSelector((state) => state.search);

    const onChange = (
        newValue,
        actionMeta
    ) => {
        switch (actionMeta.action) {
            case 'remove-value':
            case 'pop-value':
                if (actionMeta.removedValue.isFixed) {
                    return;
                }
                break;
            case 'clear':
                newValue = categoryOptions.filter((v) => v.isFixed);
                break;
            default:
                break;
        }

        const selectedCategory = orderOptions(newValue);
        let selectedCategoryValue = [];
        if (!!searchItems) {
            dispatch(clearSearchItems());
        }
        if (selectedCategory && selectedCategory.value) {
            dispatch(setSearchCategoryItems({ ...selectedCategory }));
            selectedCategoryValue = [selectedCategory.value];
        } else {
            dispatch(resetSearchData());
        }
        dispatch(setIsFilterOnSearch(false));
        setTimeout(() => {
            dispatch(filterDataListByCategory(selectedCategoryValue));
        }, 1);

    };

    return (
        <Select
            value={searchCategoryItems}
            styles={styles}
            isClearable={searchCategoryItems}
            name="filterByCategory"
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={onChange}
            id="filterByCategory"
            options={categoryOptions}
            placeholder="Filter By Category"
        />
    );
};

export default FilterDropdown;