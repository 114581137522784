// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './counterSlice';
import stallReducer from './features/stallDataSlice';
import dataElementsReducer from './features/dataElementsSlice';
import mapDataReducer from './features/mapDataSlice';
import searchReducer from './features/searchSlice';

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        stall: stallReducer,
        dataElements: dataElementsReducer,
        mapData: mapDataReducer,
        search: searchReducer,
    },
});
