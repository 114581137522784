export const directorySearchStalls = [
  {
    name: 'Entrance',
    category: 'Food and Beverages',
    sft: 1500,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo1.jpg',
    coordinates: [
      { levelIndex: 3, x: 67.27056971804906, z: -55.66277792409994, elevation: 0.3050001139640841 },
      { levelIndex: 3, x: 68.59207343708012, z: -57.57000427857584, elevation: 0.33012842367486783 },
      { levelIndex: 3, x: 66.52387184761126, z: -60.7211572455597, elevation: 0.3050001139640841 },
      { levelIndex: 3, x: 66.13144171331948, z: -61.107955844169126, elevation: 0.4667675423605253 },
      { levelIndex: 3, x: 69.36941175298738, z: -62.668148146901174, elevation: 0.5820568207586696 },
      { levelIndex: 3, x: 72.12544915095702, z: -62.471600438000564, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 76.17446065369732, z: -58.978430416656465, elevation: 0.3050001139640841 },
      { levelIndex: 3, x: 76.4711343993487, z: -54.816448856492805, elevation: 1.182959983792827 },
      { levelIndex: 3, x: 74.75984099590589, z: -51.246253774100424, elevation: 0.30500011396408766 },
      { levelIndex: 3, x: 71.45311390470006, z: -54.80060561436066, elevation: 3.0489998931884763 },
      { levelIndex: 3, x: 69.52220346829439, z: -53.53506604559988, elevation: 0.3050001139640841 }
    ],
  },

  {
    name: 'Akshaya',
    category: 'Food and Beverages',
    sft: 1500,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo1.jpg',
    coordinates: [
      { levelIndex: 3, x: 49.11561065632719, z: -31.95073406285715, elevation: 0.31570990981584757 },
      { levelIndex: 3, x: 52.592048685014255, z: -39.120483277189265, elevation: 0.30500011396408766 },
      { levelIndex: 3, x: 48.40089130209708, z: -40.35833256939475, elevation: 0.32658620509996084 },
      { levelIndex: 3, x: 45.24833878712427, z: -33.81110214585641, elevation: 0.3667485228262173 }
    ],
  },
  {
    name: 'MK-Levure-Naturelle',
    category: 'Electronics',
    sft: 1400,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo2.jpg',
    coordinates: [
      { levelIndex: 3, x: 39.13799594480976, z: -55.235778031001026, elevation: 0.6793758011027258 },
      { levelIndex: 3, x: 39.06181093674612, z: -50.54051578099646, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 44.00451902197619, z: -50.5598279307762, elevation: 0.30500011396408766 },
      { levelIndex: 3, x: 44.00802524228392, z: -55.18092569534086, elevation: 0.4128487625029855 }
    ],
  },
  {
    name: 'Kawata-House-of-Socks',
    category: 'Beauty and Health',
    sft: 1300,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo3.jpg',
    coordinates: [
      { levelIndex: 3, x: 36.247480262449585, z: -46.78061318787635, elevation: 0.3051118030044364 },
      { levelIndex: 3, x: 34.15125055071017, z: -46.585145242170654, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 33.9388574422269, z: -46.24545086147757, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 33.710063101721786, z: -45.981121144770505, elevation: 0.3645465058294697 },
      { levelIndex: 3, x: 33.385959402976354, z: -45.82291094127745, elevation: 0.3592855690099164 },
      { levelIndex: 3, x: 31.153724384477307, z: -46.256413653507515, elevation: 0.3279667761392382 },
      { levelIndex: 3, x: 30.802464802986826, z: -46.44363516399913, elevation: 0.32536128957259436 },
      { levelIndex: 3, x: 30.410185849830523, z: -50.21455981612033, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 30.570668348435618, z: -50.46083335969033, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 33.62668957948306, z: -50.906371384228095, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 34.77388693209171, z: -50.44617226596122, elevation: 0.3162575828575491 },
      { levelIndex: 3, x: 35.972714753766276, z: -50.556661861509646, elevation: 0.3050001139640841 },
    ]
  },
  {
    name: 'Wok-Hey',
    category: 'Electronics',
    sft: 1200,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo4.jpg',
    coordinates: [
      { levelIndex: 3, x: 60.759457683006204, z: -55.297848974503665, elevation: 0.3595206177255825 },
      { levelIndex: 3, x: 60.66120030972924, z: -51.100871200721784, elevation: 0.6134829622394129 },
      { levelIndex: 3, x: 46.54640384898953, z: -50.51059223350833, elevation: 0.34140908243114154 },
      { levelIndex: 3, x: 46.61823827896666, z: -55.23664823811791, elevation: 0.3717458420084476 },
    ]
  },
  {
    name: 'Juiceland',
    category: 'Food and Beverages',
    sft: 1100,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo5.jpg',
    coordinates: [
      { levelIndex: 3, x: 63.965990282150884, z: -35.99093103224569, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 54.574784741298, z: -38.498673437706614, elevation: 0.33330117907884826 },
      { levelIndex: 3, x: 52.8553838993137, z: -35.05290025033964, elevation: 0.3795455853323766 },
      { levelIndex: 3, x: 61.31432978207119, z: -31.15970172254087, elevation: 0.33717192155107867 },
    ]
  },
  {
    name: 'The-Soup-Spoon',
    category: 'Food and Beverages',
    sft: 1000,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo6.jpg',
    coordinates: [
      { levelIndex: 3, x: 61.17890933909213, z: -30.9173286281994, elevation: 0.4549044189021032 },
      { levelIndex: 3, x: 53.44361139464392, z: -34.598608920964345, elevation: 0.3050001139640841 },
      { levelIndex: 3, x: 51.56806300551628, z: -30.896107304003852, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 59.099332584649154, z: -26.989785315347213, elevation: 0.30500011396411253 }]
  },
  {
    name: 'Tingkat-PeraMakan',
    category: 'Fashion',
    sft: 900,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo7.jpg',
    coordinates: [
      { levelIndex: 3, x: 40.713370672238156, z: -36.1252386940815, elevation: 0.41837116514911443 },
      { levelIndex: 3, x: 45.04555658952991, z: -33.94869116005187, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 48.166900496053344, z: -40.39416254454431, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 43.61637547990653, z: -41.71772502439523, elevation: 0.30500011396408055 }]
  },
  {
    name: 'iuga',
    category: 'Electronics',
    sft: 800,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo8.jpg',
    coordinates: [
      { levelIndex: 3, x: 36.44784495452636, z: -38.30288046973458, elevation: 0.40569149309047603 },
      { levelIndex: 3, x: 40.4893952544242, z: -36.18760445826366, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 43.45110085952985, z: -41.763289348768346, elevation: 0.4435423632246902 },
      { levelIndex: 3, x: 38.96235537671902, z: -42.98239316122436, elevation: 0.6016721729987644 }]
  }, {
    name: 'ichiban',
    category: 'Beauty and Health',
    sft: 700,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo9.jpg',
    coordinates: [
      { levelIndex: 3, x: 32.54563589818261, z: -40.33762247090966, elevation: 0.5117882688174866 },
      { levelIndex: 3, x: 36.289589823660805, z: -38.39015843196212, elevation: 0.3050001139640841 },
      { levelIndex: 3, x: 38.81078879861439, z: -43.08014946274298, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 34.62252521514453, z: -44.102670506614686, elevation: 0.5567349534825574 }]
  }, {
    name: 'Esmond-Service-Sq',
    category: 'Fashion',
    sft: 600,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo10.jpg',
    coordinates: [
      { levelIndex: 3, x: 45.79275328443348, z: -44.21213580733597, elevation: 0.3246598350805847 },
      { levelIndex: 3, x: 45.60097566017959, z: -47.286680583672805, elevation: 0.44072160433096386 },
      { levelIndex: 3, x: 45.01625980852971, z: -47.76593841560795, elevation: 0.7165911076659945 },
      { levelIndex: 3, x: 38.10799275878469, z: -46.78334883943984, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 38.31515138885897, z: -45.20606316122171, elevation: 0.3649012117298156 },
    ]
  }, {
    name: 'Dancepointe-Academy',
    category: 'Electronics',
    sft: 500,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo11.jpg',
    coordinates: [
      { levelIndex: 3, x: 69.64208648518, z: -53.20410974041273, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 71.28058066017434, z: -46.76041343148602, elevation: 0.3895952913455041 },
      { levelIndex: 3, x: 75.41138243693294, z: -46.991303465270775, elevation: 0.871069024241482 },
      { levelIndex: 3, x: 75.43980474106245, z: -49.8589483147152, elevation: 0.3068439885070724 },
      { levelIndex: 3, x: 71.10366325179298, z: -54.17610669712152, elevation: 0.30500011396408766 }]
  },
  {
    name: 'Crocs',
    category: 'Fashion',
    sft: 400,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo12.jpg',
    coordinates: [
      { levelIndex: 3, x: 69.28305553206992, z: -41.36662927183805, elevation: 0.35342416286642475 },
      { levelIndex: 3, x: 76.15271452875105, z: -40.337948408708165, elevation: 0.4244054310342982 },
      { levelIndex: 3, x: 77.0605581859522, z: -40.544722935129144, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 76.9300796728142, z: -42.63030181471818, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 75.47930853410848, z: -46.667062669492395, elevation: 0.30500011396408766 },
      { levelIndex: 3, x: 73.48415501352616, z: -46.445261766702, elevation: 0.30500011396408766 },
      { levelIndex: 3, x: 71.25628598516877, z: -46.53942576265697, elevation: 0.30500011396408055 }]
  }, {
    name: 'Burger-King',
    category: 'Food and Beverages',
    sft: 300,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo13.jpg',
    coordinates: [
      { levelIndex: 3, x: 68.25705645254864, z: -38.09091647037433, elevation: 2.6036475504484464 },
      { levelIndex: 3, x: 73.58007569853959, z: -34.62890091236044, elevation: 0.3700933226848342 },
      { levelIndex: 3, x: 75.29125481371076, z: -36.49010943261407, elevation: 0.4953020551305123 },
      { levelIndex: 3, x: 76.55824764491526, z: -38.45720385591232, elevation: 0.46519590221480556 },
      { levelIndex: 3, x: 76.86479792403894, z: -40.32452852903634, elevation: 0.8350528295070099 },
      { levelIndex: 3, x: 75.64820945189092, z: -40.20893671016549, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 69.24602839640328, z: -41.18805775358595, elevation: 0.30500011396408055 }]
  },
  {
    name: 'watsons',
    category: 'Beauty and Health',
    sft: 200,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo14.jpg',
    coordinates: [
      { levelIndex: 3, x: 65.40867375407063, z: -33.86738551102031, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 70.6579199921025, z: -31.490536358293255, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 73.42018761356681, z: -34.44139579452871, elevation: 0.4788478368564171 },
      { levelIndex: 3, x: 67.51394653737448, z: -37.776887957301355, elevation: 0.3878669100137593 }]
  },
  {
    name: 'umisushi',
    category: 'Food and Beverages',
    sft: 1498,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo15.jpg',
    coordinates: [
      { levelIndex: 3, x: 62.78964439654679, z: -28.946500840397793, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 68.2998423954014, z: -26.47734072887999, elevation: 0.3692666529992472 },
      { levelIndex: 3, x: 70.49765818012162, z: -31.380590504517233, elevation: 0.3502935581176807 },
      { levelIndex: 3, x: 65.35005290047802, z: -33.681886869789736, elevation: 0.3329778863113937 }]
  },
  {
    name: 'stuffd',
    category: 'Homelife',
    sft: 1378,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo16.jpg',
    coordinates: [
      { levelIndex: 3, x: 68.28690262825089, z: -26.31885754244887, elevation: 0.36036306877928226 },
      { levelIndex: 3, x: 62.74189325794123, z: -28.793288654417125, elevation: 0.39719109423847954 },
      { levelIndex: 3, x: 60.73864715824149, z: -24.792145207300464, elevation: 0.40943692264320575 },
      { levelIndex: 3, x: 59.614971216735526, z: -24.23915592977225, elevation: 0.39432163404496023 },
      { levelIndex: 3, x: 60.73241592526192, z: -23.65321588633132, elevation: 0.34804182982894005 },
      { levelIndex: 3, x: 60.61940743394038, z: -23.47866101407899, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 59.03844396443593, z: -24.324762884700228, elevation: 0.3050001139640841 },
      { levelIndex: 3, x: 56.89530988952503, z: -20.318734421583486, elevation: 0.3050001139642937 },
      { levelIndex: 3, x: 63.74571537897673, z: -17.212219335459388, elevation: 0.24820895884653638 }]
  },
  {
    name: 'sin-kang-tum-clinic',
    category: 'Beauty and Health',
    sft: 1267,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo17.png',
    coordinates: [
      { levelIndex: 3, x: 48.435615736493226, z: -24.17783868857815, elevation: 0.30500011396407345 },
      { levelIndex: 3, x: 56.68801268544682, z: -20.407054290845213, elevation: 0.305000113964077 },
      { levelIndex: 3, x: 58.79624921371125, z: -24.407553035052626, elevation: 0.4067054245666135 },
      { levelIndex: 3, x: 50.60573325480162, z: -28.25726672189331, elevation: 0.30500011396408055 }]
  },
  {
    name: 'Parkwey-Shenton',
    category: 'Electronics',
    sft: 1156,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo18.png',
    coordinates: [
      { levelIndex: 3, x: 42.41146225207809, z: -32.39870492117398, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 40.167038232669185, z: -28.046818934006215, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 48.25159184292698, z: -24.246909712818425, elevation: 0.30500011396416227 },
      { levelIndex: 3, x: 50.43361701608992, z: -28.386915691328113, elevation: 0.30500011396408055 }]
  },
  {
    name: 'QB-house',
    category: 'Beauty and Health',
    sft: 1145,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo19.jpg',
    coordinates: [
      { levelIndex: 3, x: 37.50390049971817, z: -34.88055341366139, elevation: 0.30500011396408766 },
      { levelIndex: 3, x: 35.292648703386384, z: -30.208748660047533, elevation: 0.3478920537571355 },
      { levelIndex: 3, x: 39.957374991740174, z: -28.08348606368584, elevation: 0.40859236926566567 },
      { levelIndex: 3, x: 42.23918854210817, z: -32.492955288579736, elevation: 0.30500011396408055 }]
  },
  {
    name: 'Saizeriya',
    category: 'Food and Beverages',
    sft: 1023,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo20.png',
    coordinates: [
      { levelIndex: 3, x: 30.89567642282241, z: -38.09885652627877, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 28.640149241565744, z: -33.16598169235066, elevation: 0.44036762862208434 },
      { levelIndex: 3, x: 35.14530103942201, z: -30.31800827473367, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 37.322125555625675, z: -34.96330727340675, elevation: 0.3050001139640841 }]
  },
  {
    name: 'Refash',
    category: 'Electronics',
    sft: 987,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo21.png',
    coordinates: [
      { levelIndex: 3, x: 22.671242632623265, z: -35.71301562362146, elevation: 0.30500011396408766 },
      { levelIndex: 3, x: 28.45556370586594, z: -33.245140392950624, elevation: 0.42318270627372456 },
      { levelIndex: 3, x: 30.660859599594772, z: -38.20607227308535, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 24.56225499152463, z: -39.32615833929689, elevation: 0.6343908738286466 }]
  },
  {
    name: 'Parkwey-Shenton',
    category: 'Electronics',
    sft: 876,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo18.png',
    coordinates: [
      { levelIndex: 3, x: 18.475609168110815, z: -40.76365169511385, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 18.4783959582983, z: -36.155551961346056, elevation: 0.3050001139640841 },
      { levelIndex: 3, x: 21.670151916364578, z: -36.12968310746544, elevation: 0.3050001139640841 },
      { levelIndex: 3, x: 22.44545639495144, z: -35.77531716163215, elevation: 0.30500011396408766 },
      { levelIndex: 3, x: 24.399547835028844, z: -39.34036517052562, elevation: 0.44350408076033787 },
      { levelIndex: 3, x: 23.66640506653682, z: -40.77531083979709, elevation: 0.3052052909709424 }]
  },
  {
    name: 'QB-House',
    category: 'Homelife',
    sft: 765,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo23.jpg',
    coordinates: [
      { levelIndex: 3, x: 18.521414844798045, z: -45.07308122667697, elevation: 0.4573316924318718 },
      { levelIndex: 3, x: 18.48959577685049, z: -40.92531083979709, elevation: 0.40792109103705343 },
      { levelIndex: 3, x: 23.656197562831824, z: -40.950371720208956, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 23.651694644017404, z: -45.06807853176434, elevation: 0.30500011396408055 }]
  }, {
    name: 'Rainbow-Cove',
    category: 'Homelife',
    sft: 654,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo24.jpg',
    coordinates: [
      { levelIndex: 3, x: 18.46431521047056, z: -49.916639892264385, elevation: 0.3682933727367974 },
      { levelIndex: 3, x: 18.475132960250868, z: -45.223081226676975, elevation: 0.43474298278254153 },
      { levelIndex: 3, x: 23.676237196603722, z: -45.25694374590122, elevation: 0.30570488590415934 },
      { levelIndex: 3, x: 23.622312659043633, z: -49.92495058847445, elevation: 0.5074888081658919 }]
  }, {
    name: 'Mc-Donalds',
    category: 'Food and Beverages',
    sft: 543,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo25.jpg',
    coordinates: [
      { levelIndex: 3, x: 23.66655685537857, z: -55.21368156899808, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 18.478578370747044, z: -55.23577859345818, elevation: 0.35313040422411746 },
      { levelIndex: 3, x: 18.464314825641488, z: -50.093449108813445, elevation: 0.30965535663020916 },
      { levelIndex: 3, x: 23.68712805187487, z: -50.12551478985486, elevation: 0.30500011396408055 }]
  },
  {
    name: 'Fair-Price',
    category: 'Electronics',
    sft: 432,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo26.jpg',
    coordinates: [
      { levelIndex: 3, x: 18.464315341354364, z: -59.71225903523132, elevation: 0.3879710045059035 },
      { levelIndex: 3, x: 18.47066397883875, z: -55.40387474192862, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 23.63438115723777, z: -55.42457194517531, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 23.572428287333224, z: -59.74704282246154, elevation: 0.7122791012028209 }]
  }, {
    name: 'Flower-Matters',
    category: 'Beauty and Health',
    sft: 321,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo27.jpg',
    coordinates: [
      { levelIndex: 3, x: 30.73920175649301, z: -62.42657832518956, elevation: 0.6974765102898637 },
      { levelIndex: 3, x: 30.93594489305824, z: -57.50771960968002, elevation: 0.376996377859399 },
      { levelIndex: 3, x: 39.34968300172445, z: -57.674827358354925, elevation: 0.42548141416886054 },
      { levelIndex: 3, x: 39.34968303658962, z: -62.3473256125024, elevation: 0.6143321904846601 }]
  },
  {
    name: 'Eleven7',
    category: 'Food and Beverages',
    sft: 210,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo28.jpg',
    coordinates: [
      { levelIndex: 3, x: 39.58044598840342, z: -57.60280110243266, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 39.55132822323337, z: -62.44713618080408, elevation: 0.534888932670075 },
      { levelIndex: 3, x: 45.469255402700334, z: -62.38037091531756, elevation: 0.43624488697479435 },
      { levelIndex: 3, x: 45.46925547924017, z: -57.54629828637413, elevation: 0.5445608950262262 }]
  }, {
    name: 'Mister-mint',
    category: 'Homelife',
    sft: 1412,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo29.jpg',
    coordinates: [
      { levelIndex: 3, x: 51.43792356015642, z: -57.54516842969896, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 51.48568676875025, z: -62.447076745268994, elevation: 0.45019487402869274 },
      { levelIndex: 3, x: 45.69251495182684, z: -62.412363633624004, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 45.61925547950144, z: -57.52979715136859, elevation: 0.34694731772561127 }]
  },
  {
    name: 'Creative',
    category: 'Electronics',
    sft: 1323,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo30.jpg',
    coordinates: [
      { levelIndex: 3, x: 57.47078187239056, z: -62.727076473322185, elevation: 0.3708596787677614 },
      { levelIndex: 3, x: 51.665647661145925, z: -62.52343281715344, elevation: 0.43725223021922943 },
      { levelIndex: 3, x: 51.700419640413784, z: -57.55719691392487, elevation: 0.30500011396408055 },
      { levelIndex: 3, x: 57.5228431557095, z: -57.55725937620571, elevation: 0.30500011396408055 }]
  },
  {
    name: 'chiropractic',
    category: 'Beauty and Health',
    sft: 1234,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo31.jpg',
    coordinates: [
      { levelIndex: 2, x: 63.184233732328934, z: -62.81771335323464, elevation: 0.3609055264801242 },
      { levelIndex: 2, x: 57.710105751127195, z: -62.69204687883756, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 57.67961544839895, z: -57.52428125049426, elevation: 0.34088417704463936 },
      { levelIndex: 2, x: 63.11423404874576, z: -57.70137026435148, elevation: 0.410437068498549 }]
  },
  {
    name: 'Adeline',
    category: 'Homelife',
    sft: 1145,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo32.jpg',
    coordinates: [
      { levelIndex: 2, x: 49.11561065632719, z: -31.95073406285715, elevation: 0.31570990981584757 },
      { levelIndex: 2, x: 52.592048685014255, z: -39.120483277189265, elevation: 0.30500011396408766 },
      { levelIndex: 2, x: 48.40089130209708, z: -40.35833256939475, elevation: 0.32658620509996084 },
      { levelIndex: 2, x: 45.24833878712427, z: -33.81110214585641, elevation: 0.3667485228262173 }
    ],
  },
  {
    name: 'Dental-Hub',
    category: 'Fashion',
    sft: 1056,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo33.jpg',
    coordinates: [
      { levelIndex: 2, x: 39.13799594480976, z: -55.235778031001026, elevation: 0.6793758011027258 },
      { levelIndex: 2, x: 39.06181093674612, z: -50.54051578099646, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 44.00451902197619, z: -50.5598279307762, elevation: 0.30500011396408766 },
      { levelIndex: 2, x: 44.00802524228392, z: -55.18092569534086, elevation: 0.4128487625029855 }
    ],
  },
  {
    name: 'Colourful-Cafe',
    category: 'Beauty and Health',
    sft: 967,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo34.png',
    coordinates: [
      { levelIndex: 2, x: 36.247480262449585, z: -46.78061318787635, elevation: 0.3051118030044364 },
      { levelIndex: 2, x: 34.15125055071017, z: -46.585145242170654, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 33.9388574422269, z: -46.24545086147757, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 33.710063101721786, z: -45.981121144770505, elevation: 0.3645465058294697 },
      { levelIndex: 2, x: 33.385959402976354, z: -45.82291094127745, elevation: 0.3592855690099164 },
      { levelIndex: 2, x: 31.153724384477307, z: -46.256413653507515, elevation: 0.3279667761392382 },
      { levelIndex: 2, x: 30.802464802986826, z: -46.44363516399913, elevation: 0.32536128957259436 },
      { levelIndex: 2, x: 30.410185849830523, z: -50.21455981612033, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 30.570668348435618, z: -50.46083335969033, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 33.62668957948306, z: -50.906371384228095, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 34.77388693209171, z: -50.44617226596122, elevation: 0.3162575828575491 },
      { levelIndex: 2, x: 35.972714753766276, z: -50.556661861509646, elevation: 0.3050001139640841 },
    ]
  },
  {
    name: 'Coluti-Vision',
    category: 'Food and Beverages',
    sft: 967,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo35.png',
    coordinates: [
      { levelIndex: 2, x: 60.759457683006204, z: -55.297848974503665, elevation: 0.3595206177255825 },
      { levelIndex: 2, x: 60.66120030972924, z: -51.100871200721784, elevation: 0.6134829622394129 },
      { levelIndex: 2, x: 46.54640384898953, z: -50.51059223350833, elevation: 0.34140908243114154 },
      { levelIndex: 2, x: 46.61823827896666, z: -55.23664823811791, elevation: 0.3717458420084476 },
    ]
  },
  {
    name: 'Daiso',
    category: 'Electronics',
    sft: 878,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo36.jpg',
    coordinates: [
      { levelIndex: 2, x: 63.965990282150884, z: -35.99093103224569, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 54.574784741298, z: -38.498673437706614, elevation: 0.33330117907884826 },
      { levelIndex: 2, x: 52.8553838993137, z: -35.05290025033964, elevation: 0.3795455853323766 },
      { levelIndex: 2, x: 61.31432978207119, z: -31.15970172254087, elevation: 0.33717192155107867 },
    ]
  },
  {
    name: 'DBS',
    category: 'Beauty and Health',
    sft: 789,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo37.jpg',
    coordinates: [
      { levelIndex: 2, x: 61.17890933909213, z: -30.9173286281994, elevation: 0.4549044189021032 },
      { levelIndex: 2, x: 53.44361139464392, z: -34.598608920964345, elevation: 0.3050001139640841 },
      { levelIndex: 2, x: 51.56806300551628, z: -30.896107304003852, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 59.099332584649154, z: -26.989785315347213, elevation: 0.30500011396411253 }]
  },
  {
    name: 'D-Penyetz',
    category: 'Fashion',
    sft: 689,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo38.png',
    coordinates: [
      { levelIndex: 2, x: 40.713370672238156, z: -36.1252386940815, elevation: 0.41837116514911443 },
      { levelIndex: 2, x: 45.04555658952991, z: -33.94869116005187, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 48.166900496053344, z: -40.39416254454431, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 43.61637547990653, z: -41.71772502439523, elevation: 0.30500011396408055 }]
  },
  {
    name: 'Goldlion',
    category: 'Electronics',
    sft: 590,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo39.jpg',
    coordinates: [
      { levelIndex: 2, x: 36.44784495452636, z: -38.30288046973458, elevation: 0.40569149309047603 },
      { levelIndex: 2, x: 40.4893952544242, z: -36.18760445826366, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 43.45110085952985, z: -41.763289348768346, elevation: 0.4435423632246902 },
      { levelIndex: 2, x: 38.96235537671902, z: -42.98239316122436, elevation: 0.6016721729987644 }]
  }, {
    name: 'Junior-Page',
    category: 'Food and Beverages',
    sft: 412,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo40.jpg',
    coordinates: [
      { levelIndex: 2, x: 32.54563589818261, z: -40.33762247090966, elevation: 0.5117882688174866 },
      { levelIndex: 2, x: 36.289589823660805, z: -38.39015843196212, elevation: 0.3050001139640841 },
      { levelIndex: 2, x: 38.81078879861439, z: -43.08014946274298, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 34.62252521514453, z: -44.102670506614686, elevation: 0.5567349534825574 }]
  }, {
    name: 'Jia-Xang-Mee',
    category: 'Food and Beverages',
    sft: 333,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo41.jpg',
    coordinates: [
      { levelIndex: 2, x: 45.79275328443348, z: -44.21213580733597, elevation: 0.3246598350805847 },
      { levelIndex: 2, x: 45.60097566017959, z: -47.286680583672805, elevation: 0.44072160433096386 },
      { levelIndex: 2, x: 45.01625980852971, z: -47.76593841560795, elevation: 0.7165911076659945 },
      { levelIndex: 2, x: 38.10799275878469, z: -46.78334883943984, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 38.31515138885897, z: -45.20606316122171, elevation: 0.3649012117298156 },
    ]
  }, {
    name: 'Unity',
    category: 'Beauty and Health',
    sft: 423,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo42.jpg',
    coordinates: [
      { levelIndex: 2, x: 69.64208648518, z: -53.20410974041273, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 71.28058066017434, z: -46.76041343148602, elevation: 0.3895952913455041 },
      { levelIndex: 2, x: 75.41138243693294, z: -46.991303465270775, elevation: 0.871069024241482 },
      { levelIndex: 2, x: 75.43980474106245, z: -49.8589483147152, elevation: 0.3068439885070724 },
      { levelIndex: 2, x: 71.10366325179298, z: -54.17610669712152, elevation: 0.30500011396408766 }]
  },
  {
    name: 'Canton-Paradise',
    category: 'Homelife',
    sft: 765,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo43.jpg',
    coordinates: [
      { levelIndex: 2, x: 69.28305553206992, z: -41.36662927183805, elevation: 0.35342416286642475 },
      { levelIndex: 2, x: 76.15271452875105, z: -40.337948408708165, elevation: 0.4244054310342982 },
      { levelIndex: 2, x: 77.0605581859522, z: -40.544722935129144, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 76.9300796728142, z: -42.63030181471818, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 75.47930853410848, z: -46.667062669492395, elevation: 0.30500011396408766 },
      { levelIndex: 2, x: 73.48415501352616, z: -46.445261766702, elevation: 0.30500011396408766 },
      { levelIndex: 2, x: 71.25628598516877, z: -46.53942576265697, elevation: 0.30500011396408055 }]
  }, {
    name: 'Crystophari',
    category: 'Fashion',
    sft: 976,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo44.jpg',
    coordinates: [
      { levelIndex: 2, x: 68.25705645254864, z: -38.09091647037433, elevation: 2.6036475504484464 },
      { levelIndex: 2, x: 73.58007569853959, z: -34.62890091236044, elevation: 0.3700933226848342 },
      { levelIndex: 2, x: 75.29125481371076, z: -36.49010943261407, elevation: 0.4953020551305123 },
      { levelIndex: 2, x: 76.55824764491526, z: -38.45720385591232, elevation: 0.46519590221480556 },
      { levelIndex: 2, x: 76.86479792403894, z: -40.32452852903634, elevation: 0.8350528295070099 },
      { levelIndex: 2, x: 75.64820945189092, z: -40.20893671016549, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 69.24602839640328, z: -41.18805775358595, elevation: 0.30500011396408055 }]
  },
  {
    name: 'Wanton-Noodle',
    category: 'Food and Beverages',
    sft: 246,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo45.jpg',
    coordinates: [
      { levelIndex: 2, x: 65.40867375407063, z: -33.86738551102031, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 70.6579199921025, z: -31.490536358293255, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 73.42018761356681, z: -34.44139579452871, elevation: 0.4788478368564171 },
      { levelIndex: 2, x: 67.51394653737448, z: -37.776887957301355, elevation: 0.3878669100137593 }]
  },
  {
    name: 'Coffee-Bean',
    category: 'Electronics',
    sft: 890,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo46.jpg',
    coordinates: [
      { levelIndex: 2, x: 62.78964439654679, z: -28.946500840397793, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 68.2998423954014, z: -26.47734072887999, elevation: 0.3692666529992472 },
      { levelIndex: 2, x: 70.49765818012162, z: -31.380590504517233, elevation: 0.3502935581176807 },
      { levelIndex: 2, x: 65.35005290047802, z: -33.681886869789736, elevation: 0.3329778863113937 }]
  },
  {
    name: 'aiki-Forest',
    category: 'Beauty and Health',
    sft: 786,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo47.jpg',
    coordinates: [
      { levelIndex: 2, x: 68.28690262825089, z: -26.31885754244887, elevation: 0.36036306877928226 },
      { levelIndex: 2, x: 62.74189325794123, z: -28.793288654417125, elevation: 0.39719109423847954 },
      { levelIndex: 2, x: 60.73864715824149, z: -24.792145207300464, elevation: 0.40943692264320575 },
      { levelIndex: 2, x: 59.614971216735526, z: -24.23915592977225, elevation: 0.39432163404496023 },
      { levelIndex: 2, x: 60.73241592526192, z: -23.65321588633132, elevation: 0.34804182982894005 },
      { levelIndex: 2, x: 60.61940743394038, z: -23.47866101407899, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 59.03844396443593, z: -24.324762884700228, elevation: 0.3050001139640841 },
      { levelIndex: 2, x: 56.89530988952503, z: -20.318734421583486, elevation: 0.3050001139642937 },
      { levelIndex: 2, x: 63.74571537897673, z: -17.212219335459388, elevation: 0.24820895884653638 }]
  },
  {
    name: 'BFT',
    category: 'Fashion',
    sft: 1290,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo48.jpeg',
    coordinates: [
      { levelIndex: 2, x: 48.435615736493226, z: -24.17783868857815, elevation: 0.30500011396407345 },
      { levelIndex: 2, x: 56.68801268544682, z: -20.407054290845213, elevation: 0.305000113964077 },
      { levelIndex: 2, x: 58.79624921371125, z: -24.407553035052626, elevation: 0.4067054245666135 },
      { levelIndex: 2, x: 50.60573325480162, z: -28.25726672189331, elevation: 0.30500011396408055 }]
  },
  {
    name: 'Heavenly-Wang',
    category: 'Homelife',
    sft: 1500,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo49.png',
    coordinates: [
      { levelIndex: 2, x: 42.41146225207809, z: -32.39870492117398, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 40.167038232669185, z: -28.046818934006215, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 48.25159184292698, z: -24.246909712818425, elevation: 0.30500011396416227 },
      { levelIndex: 2, x: 50.43361701608992, z: -28.386915691328113, elevation: 0.30500011396408055 }]
  },
  {
    name: 'Chong-Pang-Old-Time-Flavour',
    category: 'Food and Beverages',
    sft: 1400,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo50.png',
    coordinates: [
      { levelIndex: 2, x: 37.50390049971817, z: -34.88055341366139, elevation: 0.30500011396408766 },
      { levelIndex: 2, x: 35.292648703386384, z: -30.208748660047533, elevation: 0.3478920537571355 },
      { levelIndex: 2, x: 39.957374991740174, z: -28.08348606368584, elevation: 0.40859236926566567 },
      { levelIndex: 2, x: 42.23918854210817, z: -32.492955288579736, elevation: 0.30500011396408055 }]
  },
  {
    name: 'Hundred-Grains',
    category: 'Electronics',
    sft: 1235,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo51.jpeg',
    coordinates: [
      { levelIndex: 2, x: 30.89567642282241, z: -38.09885652627877, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 28.640149241565744, z: -33.16598169235066, elevation: 0.44036762862208434 },
      { levelIndex: 2, x: 35.14530103942201, z: -30.31800827473367, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 37.322125555625675, z: -34.96330727340675, elevation: 0.3050001139640841 }]
  },
  {
    name: 'Kia-oh-Don',
    category: 'Beauty and Health',
    sft: 1413,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo52.jpg',
    coordinates: [
      { levelIndex: 2, x: 22.671242632623265, z: -35.71301562362146, elevation: 0.30500011396408766 },
      { levelIndex: 2, x: 28.45556370586594, z: -33.245140392950624, elevation: 0.42318270627372456 },
      { levelIndex: 2, x: 30.660859599594772, z: -38.20607227308535, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 24.56225499152463, z: -39.32615833929689, elevation: 0.6343908738286466 }]
  },
  {
    name: 'Kiskin',
    category: 'Beauty and Health',
    sft: 234,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo53.jpg',
    coordinates: [
      { levelIndex: 2, x: 18.475609168110815, z: -40.76365169511385, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 18.4783959582983, z: -36.155551961346056, elevation: 0.3050001139640841 },
      { levelIndex: 2, x: 21.670151916364578, z: -36.12968310746544, elevation: 0.3050001139640841 },
      { levelIndex: 2, x: 22.44545639495144, z: -35.77531716163215, elevation: 0.30500011396408766 },
      { levelIndex: 2, x: 24.399547835028844, z: -39.34036517052562, elevation: 0.44350408076033787 },
      { levelIndex: 2, x: 23.66640506653682, z: -40.77531083979709, elevation: 0.3052052909709424 }]
  },
  {
    name: 'Liho-Tea',
    category: 'Homelife',
    sft: 765,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo54.jpg',
    coordinates: [
      { levelIndex: 2, x: 18.521414844798045, z: -45.07308122667697, elevation: 0.4573316924318718 },
      { levelIndex: 2, x: 18.48959577685049, z: -40.92531083979709, elevation: 0.40792109103705343 },
      { levelIndex: 2, x: 23.656197562831824, z: -40.950371720208956, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 23.651694644017404, z: -45.06807853176434, elevation: 0.30500011396408055 }]
  }, {
    name: 'Nuodle',
    category: 'Food and Beverages',
    sft: 978,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo55.jpg',
    coordinates: [
      { levelIndex: 2, x: 18.46431521047056, z: -49.916639892264385, elevation: 0.3682933727367974 },
      { levelIndex: 2, x: 18.475132960250868, z: -45.223081226676975, elevation: 0.43474298278254153 },
      { levelIndex: 2, x: 23.676237196603722, z: -45.25694374590122, elevation: 0.30570488590415934 },
      { levelIndex: 2, x: 23.622312659043633, z: -49.92495058847445, elevation: 0.5074888081658919 }]
  }, {
    name: 'oldchang-kee',
    category: 'Electronics',
    sft: 967,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo56.jpg',
    coordinates: [
      { levelIndex: 2, x: 23.66655685537857, z: -55.21368156899808, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 18.478578370747044, z: -55.23577859345818, elevation: 0.35313040422411746 },
      { levelIndex: 2, x: 18.464314825641488, z: -50.093449108813445, elevation: 0.30965535663020916 },
      { levelIndex: 2, x: 23.68712805187487, z: -50.12551478985486, elevation: 0.30500011396408055 }]
  },
  {
    name: 'Akshaya',
    category: 'Food and Beverages',
    sft: 1234,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo1.jpg',
    coordinates: [
      { levelIndex: 2, x: 18.464315341354364, z: -59.71225903523132, elevation: 0.3879710045059035 },
      { levelIndex: 2, x: 18.47066397883875, z: -55.40387474192862, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 23.63438115723777, z: -55.42457194517531, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 23.572428287333224, z: -59.74704282246154, elevation: 0.7122791012028209 }]
  }, {
    name: 'MK-Levure-Naturelle',
    category: 'Electronics',
    sft: 1234,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo2.jpg',
    coordinates: [
      { levelIndex: 2, x: 30.73920175649301, z: -62.42657832518956, elevation: 0.6974765102898637 },
      { levelIndex: 2, x: 30.93594489305824, z: -57.50771960968002, elevation: 0.376996377859399 },
      { levelIndex: 2, x: 39.34968300172445, z: -57.674827358354925, elevation: 0.42548141416886054 },
      { levelIndex: 2, x: 39.34968303658962, z: -62.3473256125024, elevation: 0.6143321904846601 }]
  },
  {
    name: 'Akshaya',
    category: 'Food and Beverages',
    sft: 1345,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo1.jpg',
    coordinates: [
      { levelIndex: 2, x: 39.58044598840342, z: -57.60280110243266, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 39.55132822323337, z: -62.44713618080408, elevation: 0.534888932670075 },
      { levelIndex: 2, x: 45.469255402700334, z: -62.38037091531756, elevation: 0.43624488697479435 },
      { levelIndex: 2, x: 45.46925547924017, z: -57.54629828637413, elevation: 0.5445608950262262 }]
  }, {
    name: 'Wok-Hey',
    category: 'Electronics',
    sft: 1500,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo4.jpg',
    coordinates: [
      { levelIndex: 2, x: 51.43792356015642, z: -57.54516842969896, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 51.48568676875025, z: -62.447076745268994, elevation: 0.45019487402869274 },
      { levelIndex: 2, x: 45.69251495182684, z: -62.412363633624004, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 45.61925547950144, z: -57.52979715136859, elevation: 0.34694731772561127 }]
  },
  {
    name: 'Juiceland',
    category: 'Food and Beverages',
    sft: 1098,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo5.jpg',
    coordinates: [
      { levelIndex: 2, x: 57.47078187239056, z: -62.727076473322185, elevation: 0.3708596787677614 },
      { levelIndex: 2, x: 51.665647661145925, z: -62.52343281715344, elevation: 0.43725223021922943 },
      { levelIndex: 2, x: 51.700419640413784, z: -57.55719691392487, elevation: 0.30500011396408055 },
      { levelIndex: 2, x: 57.5228431557095, z: -57.55725937620571, elevation: 0.30500011396408055 }]
  },
  {
    name: 'The-Soup-Spoon',
    category: 'Food and Beverages',
    sft: 1175,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo6.jpg',
    coordinates: [
      { levelIndex: 1, x: 30.735618472093993, z: -62.38617583202944, elevation: 0.41232281609054056 },
      { levelIndex: 1, x: 30.766827426946836, z: -51.991986207393694, elevation: 0.3108460040494059 },
      { levelIndex: 1, x: 40.14104684747915, z: -51.991986207393694, elevation: 0.629279809976591 },
      { levelIndex: 1, x: 39.99301845682765, z: -62.520236241368444, elevation: 0.5657574838146253 }]
  }, {
    name: 'Tingkat-PeraMakan',
    category: 'Fashion',
    sft: 1086,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo7.jpg',
    coordinates: [
      { levelIndex: 1, x: 45.39275893641587, z: -52.03921417398997, elevation: 0.3050002017021214 },
      { levelIndex: 1, x: 40.36707751948905, z: -52.067851160560814, elevation: 0.30500020170211783 },
      { levelIndex: 1, x: 40.451950084268425, z: -62.52023625170079, elevation: 0.5512466251468808 },
      { levelIndex: 1, x: 45.46925616402805, z: -62.448102047427234, elevation: 0.9229208596052239 }]
  },
  {
    name: 'iuga',
    category: 'Electronics',
    sft: 1154,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo8.jpg',
    coordinates: [
      { levelIndex: 1, x: 51.91400432996846, z: -62.47791712680373, elevation: 0.3050002017021214 },
      { levelIndex: 1, x: 45.75277281839128, z: -62.45682968368964, elevation: 0.3050002017021214 },
      { levelIndex: 1, x: 45.65257063217206, z: -52.07051156976934, elevation: 0.3050002017021214 },
      { levelIndex: 1, x: 51.92048965259916, z: -51.99198620965412, elevation: 0.36835176626589217 }]
  },
  {
    name: 'ichiban',
    category: 'Beauty and Health',
    sft: 1256,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo9.jpg',
    coordinates: [
      { levelIndex: 1, x: 57.758097748262614, z: -62.50471267742253, elevation: 0.308721045453602 },
      { levelIndex: 1, x: 52.14968041719567, z: -62.47212910858872, elevation: 0.3050002017021214 },
      { levelIndex: 1, x: 52.085896652148925, z: -52.16215625873361, elevation: 0.521187475523913 },
      { levelIndex: 1, x: 57.74356360967529, z: -52.08256306228297, elevation: 0.30500020170211783 }]
  },
  {
    name: 'Esmond-Service-Sq',
    category: 'Fashion',
    sft: 1323,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo10.jpg',
    coordinates: [
      { levelIndex: 1, x: 63.04110583808598, z: -62.433789027216775, elevation: 0.30500020170211783 },
      { levelIndex: 1, x: 57.985161608361764, z: -62.46717983855348, elevation: 0.30500020170211783 },
      { levelIndex: 1, x: 57.97251539569421, z: -52.02438538850089, elevation: 0.38094170336416866 },
      { levelIndex: 1, x: 62.97670577805742, z: -53.139935286116774, elevation: 0.3050002017021214 }]
  },
  {
    name: 'Dancepointe-Academy',
    category: 'Electronics',
    sft: 1423,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo11.jpg',
    coordinates: [
      { levelIndex: 1, x: 63.223818291166126, z: -53.27859464455154, elevation: 0.30500020170211783 },
      { levelIndex: 1, x: 63.235978793797536, z: -59.97206708313085, elevation: 0.30500020170211606 },
      { levelIndex: 1, x: 65.7161094819185, z: -59.88634965268231, elevation: 0.34230461513650745 },
      { levelIndex: 1, x: 69.39109677305596, z: -59.977990287006364, elevation: 0.37305231947526707 },
      { levelIndex: 1, x: 70.78497379336423, z: -58.13707958426582, elevation: 0.39529876862691715 }]
  },
  {
    name: 'Crocs',
    category: 'Fashion',
    sft: 452,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo12.jpg',
    coordinates: [
      { levelIndex: 1, x: 66.42549397551056, z: -50.10871831808697, elevation: 0.3050002017021143 },
      { levelIndex: 1, x: 66.48058757342841, z: -51.668856554546686, elevation: 0.41113591429144414 },
      { levelIndex: 1, x: 72.49172357036134, z: -56.002131134948556, elevation: 0.3050002017021214 },
      { levelIndex: 1, x: 74.11702629983145, z: -53.15885959654422, elevation: 0.5893642785246769 },
      { levelIndex: 1, x: 73.23751388692942, z: -50.723532831990816, elevation: 0.31900860527538555 }]
  },
  {
    name: 'Burger-King',
    category: 'Food and Beverages',
    sft: 856,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo13.jpg',
    coordinates: [
      { levelIndex: 1, x: 74.09075785614294, z: -46.645860464531516, elevation: 0.47202582207034993 },
      { levelIndex: 1, x: 73.1016062271634, z: -49.14891075048506, elevation: 0.5026017373410738 },
      { levelIndex: 1, x: 73.17385292420923, z: -50.5677796702724, elevation: 0.3878083310794409 },
      { levelIndex: 1, x: 66.49144390857964, z: -49.91623667476319, elevation: 0.30500020170211783 },
      { levelIndex: 1, x: 68.43602967074293, z: -46.47563499232402, elevation: 0.7096215577127598 }]
  },
  {
    name: 'watsons',
    category: 'Beauty and Health',
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo14.jpg',
    coordinates: [
      { levelIndex: 1, x: 68.43194282774317, z: -46.278288342638845, elevation: 0.314429250706711 },
      { levelIndex: 1, x: 74.2073706670892, z: -46.338456611549944, elevation: 0.4932988794660922 },
      { levelIndex: 1, x: 75.36617667112014, z: -40.032151226474944, elevation: 0.3050002017021214 },
      { levelIndex: 1, x: 71.99245369658543, z: -40.52821629970974, elevation: 0.5601810564352068 }]
  },
  {
    name: 'umisushi',
    category: 'Food and Beverages',
    sft: 856,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo15.jpg',
    coordinates: [
      { levelIndex: 1, x: 65.79319767904505, z: -34.787889891703784, elevation: 0.3050002017021143 },
      { levelIndex: 1, x: 71.18452752037815, z: -32.590603514321295, elevation: 0.36992594719632343 },
      { levelIndex: 1, x: 74.43396970073663, z: -37.98701583862672, elevation: 0.3050002017021214 },
      { levelIndex: 1, x: 68.04405695547275, z: -39.66217388703619, elevation: 0.4796964459483899 }]
  },
  {
    name: 'stuffd',
    category: 'Homelife',
    sft: 676,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo16.jpg',
    coordinates: [
      { levelIndex: 1, x: 63.02539297938857, z: -28.920383205601972, elevation: 0.33022026348428035 },
      { levelIndex: 1, x: 68.41372468437993, z: -26.426685780439477, elevation: 0.5214623309171706 },
      { levelIndex: 1, x: 71.0967784371364, z: -32.421387747486236, elevation: 0.3050002017021196 },
      { levelIndex: 1, x: 65.71979699617248, z: -34.6169844009795, elevation: 0.40400965459948956 }]
  },
  {
    name: 'sin-kang-tum-clinic',
    category: 'Beauty and Health',
    sft: 567,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo17.png',
    coordinates: [
      { levelIndex: 1, x: 61.00334907461093, z: -24.681787896882867, elevation: 0.33705982040381954 },
      { levelIndex: 1, x: 66.46418467727374, z: -22.339876726544126, elevation: 0.3078462102320181 },
      { levelIndex: 1, x: 68.29426594386088, z: -26.256670542807882, elevation: 0.3050002017021214 },
      { levelIndex: 1, x: 62.97794254066833, z: -28.777058317882297, elevation: 0.3256821039075586 }]
  },
  {
    name: 'Parkwey-Shenton',
    category: 'Electronics',
    sft: 876,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo18.png',
    coordinates: [
      { levelIndex: 1, x: 59.321071701228085, z: -25.152130916511275, elevation: 0.3050002017021214 },
      { levelIndex: 1, x: 57.14556717915651, z: -20.85783840588483, elevation: 0.4509791057545325 },
      { levelIndex: 1, x: 64.15855127907386, z: -17.90829511262536, elevation: 0.3050002017021196 },
      { levelIndex: 1, x: 66.33891252706498, z: -22.091173507561926, elevation: 0.5028361297586983 }]
  },
  {
    name: 'QB-house',
    category: 'Beauty and Health',
    sft: 346,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo19.jpg',
    coordinates: [
      { levelIndex: 1, x: 53.398092078775946, z: -34.566111663118306, elevation: 0.30500020170212316 },
      { levelIndex: 1, x: 51.715049306468735, z: -30.572506775308117, elevation: 0.6260337957019324 },
      { levelIndex: 1, x: 57.774115659467576, z: -27.93923284093742, elevation: 0.3050002017021214 },
      { levelIndex: 1, x: 59.486299519619465, z: -31.803869559669327, elevation: 0.9718735183194198 }]
  },
  {
    name: 'Saizeriya',
    category: 'Food and Beverages',
    sft: 1178,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo20.png',
    coordinates: [
      { levelIndex: 1, x: 54.999361003281244, z: -39.909498312631385, elevation: 0.38174661051713166 },
      { levelIndex: 1, x: 52.84844144770116, z: -35.04843515800943, elevation: 0.3930950562890292 },
      { levelIndex: 1, x: 59.64240612705146, z: -31.89714200759468, elevation: 0.31431579055536574 },
      { levelIndex: 1, x: 61.94984279655957, z: -36.89917576675671, elevation: 0.4685575856643567 }]
  },
  {
    name: 'Refash',
    category: 'Electronics',
    sft: 567,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo21.png',
    coordinates: [
      { levelIndex: 1, x: 55.074306752883146, z: -40.05342110112544, elevation: 0.30500020170211606 },
      { levelIndex: 1, x: 56.79174601651383, z: -44.08949233187513, elevation: 0.30500020170212316 },
      { levelIndex: 1, x: 61.71490899867583, z: -43.21425891968204, elevation: 0.7219837335482051 },
      { levelIndex: 1, x: 61.72465781195478, z: -41.23561437958311, elevation: 0.3050002017021196 },
      { levelIndex: 1, x: 63.55728496475575, z: -40.75657682889206, elevation: 0.30500020170211783 },
      { levelIndex: 1, x: 62.08728930387315, z: -37.01401438411349, elevation: 0.30500020170211606 }]
  },
  {
    name: 'Parkwey-Shenton',
    category: 'Electronics',
    sft: 897,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo18.png',
    coordinates: [
      { levelIndex: 1, x: 61.74427464978431, z: -49.74482259835878, elevation: 0.3050002017021214 },
      { levelIndex: 1, x: 61.77622588538435, z: -43.451617282134805, elevation: 0.5196145300450965 },
      { levelIndex: 1, x: 56.917363068123684, z: -44.255116991627624, elevation: 0.30500020170211783 },
      { levelIndex: 1, x: 59.240006385911286, z: -49.49168299148605, elevation: 0.5674054059561726 }]
  },
  {
    name: 'QB-House',
    category: 'Homelife',
    sft: 567,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo23.jpg',
    coordinates: [
      { levelIndex: 1, x: 57.913407670486386, z: -62.46776772255186, elevation: 0.30500020170211783 },
      { levelIndex: 1, x: 63.016765825476575, z: -62.52023601264243, elevation: 0.3716291673335892 },
      { levelIndex: 1, x: 63.014048746668244, z: -53.13137808987341, elevation: 0.3499200159411373 },
      { levelIndex: 1, x: 57.908098194946206, z: -52.08223453932422, elevation: 0.34780583493703077 }]
  },
  {
    name: 'Rainbow-Cove',
    category: 'Homelife',
    sft: 909,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo24.jpg',
    coordinates: [
      { levelIndex: 1, x: 47.312274413061296, z: -49.642791848021716, elevation: 0.3050002017021196 },
      { levelIndex: 1, x: 41.391971497965635, z: -49.65535289569245, elevation: 0.30500020170211783 },
      { levelIndex: 1, x: 41.41220233425131, z: -47.635955037760866, elevation: 0.3050002017021196 },
      { levelIndex: 1, x: 47.31244822256172, z: -46.04709579134435, elevation: 0.35806772157443234 }]
  }, {
    name: 'Mc-Donalds',
    category: 'Food and Beverages',
    sft: 498,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo25.jpg',
    coordinates: [
      { levelIndex: 1, x: 51.49942961491597, z: -45.07214684608474, elevation: 0.34992887365907777 },
      { levelIndex: 1, x: 51.49933837739192, z: -49.657825285805096, elevation: 0.30500020170211783 },
      { levelIndex: 1, x: 47.54811110887307, z: -49.6900917114453, elevation: 0.3619600637319387 },
      { levelIndex: 1, x: 47.48969794087855, z: -45.96100885887543, elevation: 0.3050002017021196 }]
  },
  {
    name: 'Fair-Price',
    category: 'Electronics',
    sft: 324,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo26.jpg',
    coordinates: [
      { levelIndex: 1, x: 51.649429614915974, z: -44.98759054953839, elevation: 0.4416527855681096 },
      { levelIndex: 1, x: 53.187435247055085, z: -44.68016919181473, elevation: 0.3925091757660013 },
      { levelIndex: 1, x: 53.482872525760605, z: -44.79643372589994, elevation: 0.3431831282308968 },
      { levelIndex: 1, x: 55.772058723036196, z: -48.51016147040312, elevation: 0.5055984474903763 },
      { levelIndex: 1, x: 55.80636135268053, z: -49.027122712919024, elevation: 0.3430303199143152 },
      { levelIndex: 1, x: 55.58260385337318, z: -49.44918584759331, elevation: 0.3715533026939619 },
      { levelIndex: 1, x: 55.13116139249307, z: -49.66353138427716, elevation: 0.3050002017021187 },
      { levelIndex: 1, x: 51.68320536956354, z: -49.66902951904754, elevation: 0.3050002017021196 }]
  },
  {
    name: 'Flower-Matters',
    category: 'Beauty and Health',
    sft: 876,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo27.jpg',
    coordinates: [
      { levelIndex: 1, x: 53.810774101807986, z: -41.59851746883398, elevation: 0.35420657410490364 },
      { levelIndex: 1, x: 48.75270074405041, z: -42.41587278499586, elevation: 0.3050002017021196 },
      { levelIndex: 1, x: 45.39680860662316, z: -34.796661375279356, elevation: 0.41890518900514273 },
      { levelIndex: 1, x: 49.727009362324225, z: -32.79370507858944, elevation: 0.4202779305224773 }]
  },
  {
    name: 'Eleven7',
    category: 'Food and Beverages',
    sft: 1056,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo28.jpg',
    coordinates: [
      { levelIndex: 1, x: 48.490800295373745, z: -42.46410402200715, elevation: 0.3050002017021196 },
      { levelIndex: 1, x: 43.965285252051196, z: -43.05659775795601, elevation: 0.5240357696532545 },
      { levelIndex: 1, x: 41.06526149418852, z: -36.877265248762185, elevation: 0.3050002017021214 },
      { levelIndex: 1, x: 45.22573732078643, z: -35.04716271446179, elevation: 0.7172485926053636 }]
  }, {
    name: 'Mister-mint',
    category: 'Homelife',
    sft: 345,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo29.jpg',
    coordinates: [
      { levelIndex: 1, x: 43.77178663810797, z: -43.080115525906685, elevation: 0.30500020170211783 },
      { levelIndex: 1, x: 39.24383206405864, z: -43.86271804775819, elevation: 0.3050002017021196 },
      { levelIndex: 1, x: 36.786951855668875, z: -38.69656235826699, elevation: 0.5054623512508325 },
      { levelIndex: 1, x: 40.91549900420786, z: -36.98555672038268, elevation: 0.3703642570135557 }]
  },
  {
    name: 'Creative',
    category: 'Electronics',
    sft: 789,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo30.jpg',
    coordinates: [
      { levelIndex: 1, x: 36.556199094868006, z: -38.84325150451338, elevation: 0.3050002017021196 },
      { levelIndex: 1, x: 39.006263901902436, z: -43.87575252642476, elevation: 0.30500020170211783 },
      { levelIndex: 1, x: 33.774274803596526, z: -44.658842218517634, elevation: 0.3130036694914171 },
      { levelIndex: 1, x: 31.922139713003027, z: -40.88595056769454, elevation: 0.3050002017021196 }]
  },
  {
    name: 'chiropractic',
    category: 'Beauty and Health',
    sft: 843,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo31.jpg',
    coordinates: [
      { levelIndex: 1, x: 33.81902782875555, z: -32.23061321218, elevation: 0.34611013851716343 },
      { levelIndex: 1, x: 33.80591315649942, z: -32.23665959209846, elevation: 3.844853866478953 },
      { levelIndex: 1, x: 35.31559478186932, z: -30.755691150825527, elevation: 0.30500020170212316 },
      { levelIndex: 1, x: 35.34660362569538, z: -31.27472881702785, elevation: 0.4415079228164487 },
      { levelIndex: 1, x: 37.32009564522116, z: -35.18877338227254, elevation: 0.3693562586861887 },
      { levelIndex: 1, x: 32.89653170117417, z: -37.0420407744577, elevation: 0.6317311728042405 },
      { levelIndex: 1, x: 31.285884809876055, z: -33.41569493859727, elevation: 0.30500020170211783 }]
  },
  {
    name: 'Adeline',
    category: 'Homelife',
    sft: 1056,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo32.jpg',
    coordinates: [
      { levelIndex: 1, x: 28.209108333222538, z: -38.85200393560771, elevation: 0.43809215788810896 },
      { levelIndex: 1, x: 32.77265358243855, z: -37.132258411883676, elevation: 0.3807594554648155 },
      { levelIndex: 1, x: 31.09638086775497, z: -33.41061139104281, elevation: 0.35368419825236863 },
      { levelIndex: 1, x: 26.699410469873456, z: -35.35535489362594, elevation: 0.30500020170211783 }]
  },
  {
    name: 'Dental-Hub',
    category: 'Fashion',
    sft: 1198,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo33.jpg',
    coordinates: [
      { levelIndex: 0, x: 30.43246070070783, z: -62.76586585168707, elevation: 0.009999999776482582 },
      { levelIndex: 0, x: 31.39615713989443, z: -50.229935098890024, elevation: 0.5499410280744605 },
      { levelIndex: 0, x: 37.47458628909906, z: -50.16114976259969, elevation: 0.009999999776482582 },
      { levelIndex: 0, x: 37.45542533587643, z: -62.85768231009287, elevation: 0.030439030662265054 }]
  },
  {
    name: 'Colourful-Cafe',
    category: 'Beauty and Health',
    sft: 1298,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo34.png',
    coordinates: [
      { levelIndex: 0, x: 43.229818023663235, z: -58.05295356026588, elevation: 0.009999999776486135 },
      { levelIndex: 0, x: 37.72253705656173, z: -57.95765471947256, elevation: 0.009999999776489688 },
      { levelIndex: 0, x: 37.7045434506009, z: -62.817712695050766, elevation: 0.009999999776484358 },
      { levelIndex: 0, x: 40.882084876836764, z: -63.03203075427639, elevation: 0.009999999776484358 },
      { levelIndex: 0, x: 43.26303891893153, z: -63.08894675756744, elevation: 0.3615097252711319 }]
  },
  {
    name: 'Coluti-Vision',
    category: 'Food and Beverages',
    sft: 1432,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo35.png',
    coordinates: [
      { levelIndex: 0, x: 68.38353891594957, z: -58.31293647588624, elevation: 0.035512161771308115 },
      { levelIndex: 0, x: 67.27650914906823, z: -56.2151179548451, elevation: 0.009999999776482582 },
      { levelIndex: 0, x: 69.71174611704603, z: -54.18618600860852, elevation: 0.2764843606640959 },
      { levelIndex: 0, x: 71.23529636362788, z: -55.494245865872145, elevation: 0.1211574289536479 },
      { levelIndex: 0, x: 75.30970687810549, z: -52.22736703959786, elevation: 0.011554247528231087 },
      { levelIndex: 0, x: 76.45611884092035, z: -55.01419260178204, elevation: 0.1703940788711229 },
      { levelIndex: 0, x: 76.29532833120545, z: -58.31181969966476, elevation: 0.38524648693636365 },
      { levelIndex: 0, x: 74.65921901317225, z: -60.866740685985555, elevation: 0.06720236484951769 },
      { levelIndex: 0, x: 71.57707834681506, z: -62.56775849729353, elevation: 0.05343555917831111 },
      { levelIndex: 0, x: 68.86694137443251, z: -62.36854265591146, elevation: 0.009999999776486135 },
      { levelIndex: 0, x: 66.02173591052116, z: -60.995907406771174, elevation: 0.38372314343486735 }]
  },
  {
    name: 'Daiso',
    category: 'Electronics',
    sft: 690,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo36.jpg',
    coordinates: [
      { levelIndex: 0, x: 74.0591223894788, z: -35.844939812219785, elevation: 0.009999999776482582 },
      { levelIndex: 0, x: 76.1712638829684, z: -38.53161421273244, elevation: 0.21390597101635755 },
      { levelIndex: 0, x: 76.89447203838253, z: -40.36177362643351, elevation: 0.049591446081363344 },
      { levelIndex: 0, x: 77.02414801210102, z: -42.46580633348213, elevation: 0.13153459655077349 },
      { levelIndex: 0, x: 68.49038276121856, z: -42.98289230029018, elevation: 0.01039943264875376 },
      { levelIndex: 0, x: 66.96937268985677, z: -38.4370715543215, elevation: 0.14628956398280657 }]
  },
  {
    name: 'DBS',
    category: 'Beauty and Health',
    sft: 789,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo37.jpg',
    coordinates: [
      { levelIndex: 0, x: 64.90355256645277, z: -33.612159682712374, elevation: 0.25451351857436677 },
      { levelIndex: 0, x: 71.14235835613398, z: -31.491142376874713, elevation: 0.2843088558541922 },
      { levelIndex: 0, x: 73.94313606938559, z: -35.62911220282291, elevation: 0.033806385967016794 },
      { levelIndex: 0, x: 66.79270185759347, z: -38.31397650539409, elevation: 0.10202414396928283 }]
  },
  {
    name: 'D-Penyetz',
    category: 'Fashion',
    sft: 910,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo38.png',
    coordinates: [
      { levelIndex: 0, x: 69.64977041926477, z: -26.968713078477005, elevation: 0.009999999776484358 },
      { levelIndex: 0, x: 69.71083434396758, z: -29.347311267349507, elevation: 0.009999999776480806 },
      { levelIndex: 0, x: 71.06285226058434, z: -31.304303654517188, elevation: 0.02664634902856733 },
      { levelIndex: 0, x: 64.83848644476383, z: -33.36438030471971, elevation: 0.009999999776482582 },
      { levelIndex: 0, x: 63.49906764065353, z: -29.176494801728673, elevation: 0.009999999776484358 }]
  },
  {
    name: 'Goldlion',
    category: 'Electronics',
    sft: 456,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo39.jpg',
    coordinates: [
      { levelIndex: 0, x: 61.85967451876612, z: -24.307241734866363, elevation: 0.009999999776484358 },
      { levelIndex: 0, x: 69.34529424526578, z: -21.37413004996469, elevation: 0.020073904761286343 },
      { levelIndex: 0, x: 69.61442899697265, z: -26.781842543383643, elevation: 0.2125380495622302 },
      { levelIndex: 0, x: 63.39238119211035, z: -28.94380748299978, elevation: 0.009999999776484358 }]
  },
  {
    name: 'Junior-Page',
    category: 'Food and Beverages',
    sft: 678,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo40.jpg',
    coordinates: [
      { levelIndex: 0, x: 60.137862206131864, z: -24.781468395620387, elevation: 0.22402796321076224 },
      { levelIndex: 0, x: 58.23923369993912, z: -19.839879494202048, elevation: 0.009999999776480806 },
      { levelIndex: 0, x: 64.3361553849002, z: -17.1903442411615, elevation: 0.1507946834941567 },
      { levelIndex: 0, x: 66.06665228073935, z: -15.763546723076942, elevation: 0.11155856005494691 },
      { levelIndex: 0, x: 67.63751219117653, z: -15.928198680795944, elevation: 0.06209824679307019 },
      { levelIndex: 0, x: 68.65386837296886, z: -16.744480270637883, elevation: 0.009999999776486135 },
      { levelIndex: 0, x: 69.08654753305697, z: -18.012137715086354, elevation: 0.009999999776486135 },
      { levelIndex: 0, x: 69.37572456465674, z: -21.157274232672005, elevation: 0.00999999977647903 }]
  },
  {
    name: 'Jia-Xang-Mee',
    category: 'Food and Beverages',
    sft: 789,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo41.jpg',
    coordinates: [
      { levelIndex: 0, x: 54.376417197174845, z: -23.315710353382403, elevation: 0.06958658008974972 },
      { levelIndex: 0, x: 58.70483776474253, z: -21.6112167996742, elevation: 0.2665928198006853 },
      { levelIndex: 0, x: 60.10104261411694, z: -25.25578273491981, elevation: 0.009999999776484358 },
      { levelIndex: 0, x: 55.62983096053175, z: -26.872255586159973, elevation: 0.20041355666151794 }]
  },
  {
    name: 'Unity',
    category: 'Beauty and Health',
    sft: 1109,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo42.jpg',
    coordinates: [
      { levelIndex: 0, x: 50.92716417193539, z: -28.95490811073597, elevation: 0.009999999776486135 },
      { levelIndex: 0, x: 49.6080835847184, z: -25.392339376688035, elevation: 0.9786954649794453 },
      { levelIndex: 0, x: 54.13679495911467, z: -23.412872514525397, elevation: 0.533770250455575 },
      { levelIndex: 0, x: 55.505888662864386, z: -26.975388013284043, elevation: 0.025512492580302748 }]
  },
  {
    name: 'Canton-Paradise',
    category: 'Homelife',
    sft: 1389,
    phase: 1,
    store: 'no',
    vacantStatus: 'all',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo43.jpg',
    coordinates: [
      { levelIndex: 0, x: 47.10216960230663, z: -26.265276398371668, elevation: 2.2993849205737824 },
      { levelIndex: 0, x: 47.25607916256524, z: -26.266287403267004, elevation: 0.009999999776484358 },
      { levelIndex: 0, x: 49.34456500634069, z: -25.356028636741325, elevation: 0.3832920856555706 },
      { levelIndex: 0, x: 50.73164731821913, z: -28.915104371592538, elevation: 1.1436185687973097 },
      { levelIndex: 0, x: 46.58217195288921, z: -30.917025560146442, elevation: 0.009999999776482582 },
      { levelIndex: 0, x: 45.01835022847432, z: -26.58049407684366, elevation: 0.12212679252491299 }]
  },
  {
    name: 'Crystophari',
    category: 'Fashion',
    sft: 890,
    phase: 1,
    store: 'no',
    vacantStatus: 'vacant',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo44.jpg',
    coordinates: [
      { levelIndex: 0, x: 42.06609817885582, z: -32.90633655516851, elevation: 0.009999999776482582 },
      { levelIndex: 0, x: 40.428229435116165, z: -28.696947154839563, elevation: 0.3883336118090135 },
      { levelIndex: 0, x: 44.74180865643432, z: -26.706929863968156, elevation: 0.12423967720421913 },
      { levelIndex: 0, x: 46.33505857374253, z: -31.049814325766544, elevation: 0.03529924246997851 }]
  },
  {
    name: 'Akshaya',
    category: 'Food and Beverages',
    sft: 698,
    phase: 1,
    store: 'no',
    vacantStatus: 'Occupied',
    imageURL: 'https://realtrek-assets.s3.amazonaws.com/images/mall/logo1.jpg',
    coordinates: [
      { levelIndex: 0, x: 35.98977151542314, z: -30.755142015421242, elevation: 0.009999999776484358 },
      { levelIndex: 0, x: 40.167976339817585, z: -28.861749169268727, elevation: 0.009999999776484358 },
      { levelIndex: 0, x: 41.86727921743861, z: -32.99088827707024, elevation: 0.12698603289915233 },
      { levelIndex: 0, x: 37.60778700984096, z: -34.8217064296636, elevation: 0.009999999776487911 }]
  }
];

export const sensors = [
  {
    id: 'Ready to Occupy',
    position: {
      levelIndex: 0, x: 3.5090640898070085, z: -3.256354923487797, elevation: 0.4
    }
  }
]