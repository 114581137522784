import { createSlice } from '@reduxjs/toolkit';
import Chance from 'chance';

const chance = new Chance();

const initialState = {
    space: null,
    task: 'idle',
    mode: '3d',
    editingId: null,
    points: [],
    icons: [],
    polygons: [],
    polylines: [],
    mapData: null
};

const dataElementsSlice = createSlice({
    name: 'dataElements',
    initialState,
    reducers: {
        setSpace: (state, action) => {
            state.space = action.payload;
        },
        setTask: (state, action) => {
            state.task = action.payload;
        },
        setMode: (state, action) => {
            state.mode = action.payload;
        },
        setEditingId: (state, action) => {
            state.editingId = action.payload;
        },
        addPoint: (state, action) => {
            state.points.push(action.payload);
        },
        updatePoint: (state, action) => {
            const { id, updates } = action.payload;
            state.points = state.points.map(pt => (pt.id === id ? { ...pt, ...updates } : pt));
        },
        removePoint: (state, action) => {
            state.points = state.points.filter(pt => pt.id !== action.payload);
        },
        addIcon: (state, action) => {
            state.icons.push(action.payload);
        },
        updateIcon: (state, action) => {
            const { id, updates } = action.payload;
            state.icons = state.icons.map(icon => (icon.id === id ? { ...icon, ...updates } : icon));
        },
        removeIcon: (state, action) => {
            state.icons = state.icons.filter(icon => icon.id !== action.payload);
        },
        addPolygon: (state, action) => {
            state.polygons.push({
                id: action.payload,
                name: chance.letter({ casing: 'upper' }) + chance.integer({ min: 1, max: 9 }),
                coordinates: []
            });
        },
        addPolygonCoordinate: (state, action) => {
            const { id, coordinate } = action.payload;
            state.polygons = state.polygons.map(poly => (poly.id === id ? { ...poly, coordinates: [...poly.coordinates, coordinate] } : poly));
        },
        updatePolygonCoordinates: (state, action) => {
            const { id, coordinates } = action.payload;
            state.polygons = state.polygons.map(poly => (poly.id === id ? { ...poly, coordinates } : poly));
            console.log(id, coordinates);
        },
        removePolygon: (state, action) => {
            state.polygons = state.polygons.filter(poly => poly.id !== action.payload);
        },
        addPolyline: (state, action) => {
            state.polylines.push({
                id: action.payload,
                name: chance.letter({ casing: 'upper' }) + chance.integer({ min: 1, max: 9 }),
                coordinates: []
            });
        },
        addPolylineCoordinate: (state, action) => {
            const { id, coordinate } = action.payload;
            state.polylines = state.polylines.map(poly => (poly.id === id ? { ...poly, coordinates: [...poly.coordinates, coordinate] } : poly));
        },
        updatePolylineCoordinates: (state, action) => {
            const { id, coordinates } = action.payload;
            state.polylines = state.polylines.map(poly => (poly.id === id ? { ...poly, coordinates } : poly));
        },
        removePolyline: (state, action) => {
            state.polylines = state.polylines.filter(poly => poly.id !== action.payload);
        },
        setMapData: (state, action) => {
            state.mapData = action.payload;
        }
    }
});

export const {
    setSpace,
    setTask,
    setMode,
    setEditingId,
    addPoint,
    updatePoint,
    removePoint,
    addIcon,
    updateIcon,
    removeIcon,
    addPolygon,
    addPolygonCoordinate,
    updatePolygonCoordinates,
    removePolygon,
    addPolyline,
    addPolylineCoordinate,
    updatePolylineCoordinates,
    removePolyline,
    setMapData
} = dataElementsSlice.actions;

export default dataElementsSlice.reducer;
