import React, { Suspense, lazy, useEffect, useState } from 'react';
import './App.css';
import './styles.css';
import { resetMapData, setDataList } from './redux/features/mapDataSlice';
import { useDispatch } from 'react-redux';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { resetSearchData } from './redux/features/searchSlice';
import HamburderMobileMenu from './components/HamburgerMobileMenu';
import DirectoryFilters from './directory-search/common-components/DirectoryFilters';
import MapFilters from './components/MapFilters';

const AddDataElements = lazy(() => import('./add-data-elements/AddDataElements'));
const AddDataElementsDirectorySearch = lazy(() => import('./directory-search/AddDataElementsDirectorySearch'));

const staticUrl = "/directory-search";

const App = () => {
  const [currentPath, setCurrentPath] = useState(staticUrl);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(location);
    if (location && location.pathname) {
      // setCurrentPath(location.pathname);
      dispatch(resetMapData());
      dispatch(resetSearchData());
      dispatch(setDataList(staticUrl)); // currentPath
    }
  }, [dispatch, location]);

  const loadFilters = () => {
    console.log(currentPath);
    const filterComp = (currentPath === '/directory-search') ? <DirectoryFilters /> : <MapFilters />;
    return filterComp;
  };

  return (
    <>
      <div className="container-fluid form-container">
        <HamburderMobileMenu />
        <Suspense fallback={<div className="container">Loading...</div>}>
          <Routes>
            <Route path="/" element={<AddDataElementsDirectorySearch />}>Leasing</Route>
            {/* <Route path="/" element={<AddDataElements />}>Leasing</Route>
          <Route path="/directory-search" element={<AddDataElementsDirectorySearch />}>Directory</Route> */}
          </Routes>
        </Suspense>
        <Outlet />
        <div className="menu_sidebar">
          <div className='close-menu'>
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
          {loadFilters()}
        </div>

        <div className="row no-gutters footer">
          <div className="col-md-12 col-sm-12 text-center">
            Designed
            with <i className="fa fa-heart text-danger"></i> by
            RealTrek. All rights reserved.
          </div>
        </div>

      </div>
    </>
  );
}

export default App;
