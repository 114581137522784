
import { v4 as uuidv4 } from 'uuid';

export const analyticsApiUrl = "https://www.realtrek.com/api/mapAnalytics/create";
export const mapActiveStatusApiUrl = "https://www.realtrek.com/api/maps/getMapActiveState";

const createUuId = () => uuidv4(); // generate new uuId
export const generateUuid = () => {
    if (!getUuId()) {
        localStorage.setItem("uuId", createUuId()); // update with userId
    }
};

const generateSessionId = () => {
    sessionStorage.setItem("sessionId", createUuId());
};

const updateMapId = () => {
    let mapId = window.location.search.split("=")[1];
    if (!mapId) {
        mapId = "map-1";
    }
    sessionStorage.setItem("mapId", mapId);
};

const getSessionId = () => sessionStorage.getItem("sessionId");
const getUuId = () => localStorage.getItem("uuId");
const getMapId = () => sessionStorage.getItem("mapId");

export const prepareApiRequestParams = (currentObj) => ({
    mapId: getMapId(),
    uuid: getUuId(),
    sessionId: getSessionId(),
    ...currentObj
});

// initialize Uuid, sessionId, mapId
generateUuid();
generateSessionId();
updateMapId();

export const callMapStausAPi = async () => {
    return await fetch(`${mapActiveStatusApiUrl}`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ mapId: getMapId() })
    }).then(res => res.json())
        .then(res => res)
        .catch((error) => console.error(error));
};

export const callAnalyticsApi = async (analyticsRequestParams) => {
    console.log(analyticsRequestParams);
    const apiRequestParams = prepareApiRequestParams(analyticsRequestParams);
    console.log(apiRequestParams);

    return await fetch(`${analyticsApiUrl}`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(apiRequestParams)
    }).then(res => res.json())
        .then(res => res)
        .catch((error) => console.error(error));
};

export const showApiResponse = (data) => {
    if (data && data.status) {
        const status = data.status;
        if (status.statusMessage) {
            // alert(status.statusMessage);
            console.log(status.statusMessage);
        }
    }
};
// {
//     // "id": "string",
//     "mapId": mapId,
//     "uuid": uuId,
//     // "storeName": "string",
//     "sessionId": sessionId,
//     // "sessionStartTime": "2024-07-12T15:34:22.408Z",
//     // "sessionEndTime": "2024-07-12T15:34:22.408Z",
//     // "createdOn": "2024-07-12T15:34:22.408Z",
//     // "updatedOn": "2024-07-12T15:34:22.408Z",
//     // "storeViewed": true,
//     // "contactClicked": true,
//     // "storeSearched": true,
//     // "clicked": true,
//     // "detailsClicked": true,
//     "occupancyVerified": true,
//     // "navigationFound": true
// }
