import { useEffect } from "react";

const HamburderMobileMenu = () => {

    useEffect(() => {
        const hamburger = document.querySelector('.hamburger');
        const closeMenu = document.querySelector(".close-menu");
        if (hamburger) {
            const handleHamburgerClick = () => {
                const menu_sidebar = document.querySelector('.menu_sidebar');
                if (menu_sidebar) {
                    menu_sidebar.classList.toggle('active');
                    if (menu_sidebar.classList.contains('active')) {
                        closeMenu.querySelector('.fa-times').style.display = 'inline-block';
                        hamburger.querySelector('.fa-bars').style.display = 'none';
                    } else {
                        closeMenu.querySelector('.fa-times').style.display = 'none';
                        hamburger.querySelector('.fa-bars').style.display = 'inline-block';
                    }
                }
            };

            hamburger.addEventListener('click', handleHamburgerClick);
            closeMenu.addEventListener('click', handleHamburgerClick);

            // Cleanup event listener on component unmount
            return () => {
                hamburger.removeEventListener('click', handleHamburgerClick);
                closeMenu.removeEventListener('click', handleHamburgerClick);
            };
        }

        const resizeCanvas = () => {
            const canvas = document.getElementById('smplr-container');
            if (canvas) {
                canvas.style.width = '100%';
                canvas.style.height = '100%';
            }
        };

        window.addEventListener('resize', resizeCanvas);
        resizeCanvas(); // Initial resize on component mount

        return () => {
            window.removeEventListener('resize', resizeCanvas);
        };

    }, []);

    return (
        <div className="hamburger">
            <i className="fa fa-bars" aria-hidden="true"></i>
        </div>
    );
};

export default HamburderMobileMenu;