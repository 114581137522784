import FilterDropdown from "./FilterDropdown";
import Sidebar from "./logos";
import SearchableDropdown from "./SearchableDropdown";

const DirectoryFilters = () => {
    return (
        <form className="pure-form">
            <div className="row no-gutters">
                <div className="col-lg-12 section-divider search-dropdown-container">
                    <SearchableDropdown />
                </div>

                <div className="col-lg-12 section-divider search-dropdown-container">
                    <FilterDropdown />
                </div>

                <div className="col-lg-12 logo-icons-container">
                    <Sidebar />
                </div>
            </div>
        </form>
    );
};

export default DirectoryFilters;