import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsFilterOnSearch, updateSelectedItemsList } from '../../redux/features/mapDataSlice';
import { setSearchItems } from '../../redux/features/searchSlice';

const Sidebar = () => {
  const dispatch = useDispatch();
  const { isFilterOnSearch, selectedItems, filteredDataList } = useSelector((state) => state.mapData);
  const [logosList, setLogosList] = useState([]);

  const getLogosDataList = useCallback(() => {
    let currentList = [...filteredDataList];
    if (isFilterOnSearch) {
      currentList = [...selectedItems];
    }
    setLogosList(currentList);
  }, [filteredDataList, isFilterOnSearch, selectedItems]);

  useEffect(() => {
    getLogosDataList();
  }, [getLogosDataList]);

  const openSelectedStall = useCallback((stall) => {
    console.log(stall);
    if (stall && stall.name) {
      dispatch(setSearchItems({ ...stall }));
      dispatch(setIsFilterOnSearch(true));
      dispatch(updateSelectedItemsList([stall.name]));
    }
  }, [dispatch]);

  const logos = logosList.map((item, index) => (
    <div key={item.id} className="logo" onClick={() => openSelectedStall(item)}>
      <img key={'img'+item.id} id={`img${index}`} src={item.imageURL} alt={item.name} />
    </div>
  ));

  return (
    <>
      <label
        key={logosList.length}
        className='section-divider section-heading'
        id={'logosList' + logosList.length}>
        {logosList.length} shops
      </label>
      <div
        className="sidebar"
        id='sidebar'>
        {logos}
      </div>
    </>
  );
};

export default Sidebar;
