import { createSlice } from '@reduxjs/toolkit';
import { stalls } from '../../add-data-elements/data';
import { directorySearchStalls } from '../../directory-search/data';

export const defaultStall = {
    imageURL: "",
    name: "",
    sft: "",
    phase: ""
};

const initialState = {
    clickedStall: defaultStall,
    occupancyMode: false,
    viewerReady: false,
    space: null,
    occupancyFilter: 'all',
    vacantStatus: ["occupied", "expired", "vacant"],
    dataList: [],
    filteredDataList: [],
    occupancyStatusfilteredDataList: [],
    selectedItems: [],
    logosList: [],
    isFilterOnSearch: false,
    pathData: null
};

const mapDataSlice = createSlice({
    name: 'viewer',
    initialState,
    reducers: {
        resetMapData: () => initialState,
        setDataList: (state, action) => {
            let mapDataList = stalls;
            console.log(action.payload);
            if (action.payload && action.payload === '/directory-search') {
                mapDataList = directorySearchStalls;
                state.logosList = mapDataList.map((item, pos) => ({ id: pos + 1, name: item.name, imageURL: item.imageURL, value: pos + 1, label: item.name }));
            }
            state.dataList = mapDataList.map((item, pos) => ({ ...item, value: pos + 1, label: item.name }));
            if (state.filteredDataList && state.filteredDataList.length === 0) {
                state.filteredDataList = [...state.dataList]; // applyFilter(action.payload, state.occupancyFilter);
            }
        },
        filterDataList: (state, action) => {
            let filteredList = state.dataList;
            if (!!action.payload && action.payload.length > 0) {
                filteredList = state.filteredDataList.filter(item => action.payload.includes(item.name));
            }
            // console.log(filteredList);
            state.occupancyStatusfilteredDataList = [...filteredList];
        },
        filterDataListByRange: (state, action) => {
            let filteredList = state.dataList;
            if (!!action.payload) {
                filteredList = filteredList.filter(item => item.sft <= action.payload);
            }
            state.filteredDataList = [...filteredList];
        },
        filterDataListByOccupancyStatus: (state, action) => {
            // ["occupied", "expired", "vacant"];
            let filteredList = state.dataList;
            const selectedStatues = action.payload;
            if (!!selectedStatues && selectedStatues.length > 0) {
                filteredList = state.dataList.filter(item =>
                    selectedStatues.includes(item.vacantStatus.toLowerCase())
                );
                // console.log(filteredList);
                state.filteredDataList = [...filteredList];
                state.occupancyStatusfilteredDataList = [...filteredList];
            } else {
                state.filteredDataList = [];
                state.occupancyStatusfilteredDataList = [];
            }
        },
        filterDataListByCategory: (state, action) => {
            let filteredList = state.dataList;
            if (!!action.payload && action.payload.length > 0) {
                filteredList = filteredList.filter(item => action.payload.includes(item.category));
            }
            state.selectedItems = [];
            state.filteredDataList = [...filteredList];
        },
        updateSelectedItemsList: (state, action) => {
            let filteredList = [];
            if (!!action.payload && action.payload.length > 0) {
                filteredList = state.filteredDataList.filter(item => action.payload.includes(item.name));
            }
            state.selectedItems = [...filteredList];
        },
        setIsFilterOnSearch: (state, action) => {
            state.isFilterOnSearch = action.payload;
        },
        setClickedStall: (state, action) => {
            state.clickedStall = action.payload;
        },
        setVacantStatus: (state, action) => {
            state.vacantStatus = [...action.payload];
        },
        initVacantStatus: (state) => {
            state.vacantStatus = ["occupied", "expired", "vacant"];
        },
        clearVacantStatus: (state) => {
            state.vacantStatus = [];
        },
        clearDataList: (state) => {
            state.filteredDataList = [];
        },
        setOccupancyMode: (state, action) => {
            state.occupancyMode = action.payload;
        },
        setViewerReady: (state, action) => {
            state.viewerReady = action.payload;
        },
        setSpace: (state, action) => {
            state.space = action.payload;
        },
        resetSpace: (state) => {
            state.space = null;
        },
        setOccupancyFilter: (state, action) => {
            state.occupancyFilter = action.payload;
        },
        loadPathFinder: (state, action) => {
            console.log(action.payload);
            state.pathData = { ...action.payload };
        },
    },
});

const applyFilter = (dataList, filterList, key) => {
    if (!filterList) {
        return dataList;
    }
    return dataList.filter((item) =>
        filterList.includes(item[key].toLowerCase())
    );
};

export const {
    resetMapData,
    setDataList,
    filterDataList,
    filterDataListByRange,
    filterDataListByOccupancyStatus,
    filterDataListByCategory,
    updateSelectedItemsList,
    setIsFilterOnSearch,
    setClickedStall,
    setVacantStatus,
    initVacantStatus,
    clearVacantStatus,
    clearDataList,
    setOccupancyMode,
    setViewerReady,
    setSpace,
    resetSpace,
    setOccupancyFilter,
    loadPathFinder
} = mapDataSlice.actions;

export default mapDataSlice.reducer;
